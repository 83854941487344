import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Category, Product } from 'Types/Common'
import { ServiceProvider } from 'Types/Space'

export interface AirtableDataSchema {
  categories: Category[]
  activeCategory: Category
  products: Product[]
  productsFromCategory: {
    key: string
    products: Product[]
  }
  serviceProvider: ServiceProvider
}

const initialState: AirtableDataSchema = {
  categories: [],
  activeCategory: {
    id: '',
    name: '',
    description: ''
  },
  products: [],
  productsFromCategory: {
    key: '',
    products: []
  },
  serviceProvider: {
    fields: {
      Accommodation: [],
      'Food & Beverages': [],
      Activities: [],
      'Co-working Spaces': [],
      Name: '',
      'Main contact': ''
    },
    id: ''
  }
}

const airtableDataSlice = createSlice({
  name: 'airtableData',
  initialState,
  reducers: {
    setServiceProvider: (
      state,
      action: PayloadAction<{ airtableServiceProvider: ServiceProvider }>
    ) => {
      const { airtableServiceProvider } = action.payload
      state.serviceProvider = airtableServiceProvider
    },
    setCategories: (state, action: PayloadAction<{ airtableCategories: Category[] }>) => {
      const { airtableCategories } = action.payload
      const categories: Category[] = airtableCategories.map((catergory, index) => {
        return {
          ...catergory,
          ordinalNumber: index
        }
      })
      state.categories = categories
    },
    initProducts: (state, action: PayloadAction<{ airtableProducts: Product[] }>) => {
      state.products = action.payload.airtableProducts
    },
    setProductsFromCategory: (
      state,
      action: PayloadAction<{
        airtableProducts: {
          key: string
          products: Product[]
        }
      }>
    ) => {
      state.productsFromCategory = action.payload.airtableProducts
    },
    setActiveCategory: (state, action: PayloadAction<{ activeCategory: Category }>) => {
      state.activeCategory = action.payload.activeCategory
    }
  }
})

export const {
  setServiceProvider,
  setCategories,
  initProducts,
  setProductsFromCategory,
  setActiveCategory
} = airtableDataSlice.actions
export default airtableDataSlice.reducer
