import { useEffect } from 'react'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import { Layout, Image, FlowNav, FlowNavButtonProps } from '@uniqore/module'
import {
  UqTypography,
  UqBox,
  UqDivider,
  UqAlert,
  UqAlertTitle,
  UqButton,
  UqBackdrop,
  UqCircularProgress
} from '@uniqore/wrapper'
import { layoutProps } from 'Theme'
import OrderComment from 'Components/Order/OrderComment'
import FailedPaymentDialog from 'Components/Order/FailedPaymentDialog'
import InitCurrentStatus from 'Components/Order/StatusPageContent'
import { useParams } from 'react-router-dom'
import { useState } from 'react'
import ShareIcon from '@mui/icons-material/Share'
import AddIcon from '@mui/icons-material/Add'
import { RWebShare } from 'react-web-share'
import { useOrder } from 'Util'
import useTypedSelector from 'Store'
import OrderProducts from 'Components/Order/OrderProducts'
import { round } from 'Components/Cart/Helpers/RoundPrice'
import { useMutation } from '@apollo/client'
import { editSnackbar } from 'Features/snackbar'
import { CHECKOUT_CATALOG_ORDER, CANCEL_CATALOG_ORDER } from 'GraphQL/mutations'
import { useDispatch } from 'react-redux'
import { getMainWebsiteUrl } from 'Util'

const OrderStatusPage = () => {
  const { id, lang } = useParams()
  const dispatch = useDispatch()

  const { t } = useTranslation(['Main', 'ServiceProvider', 'Order'])

  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [lang])

  const orderId = id
  const { loading, refetch } = useOrder(orderId ?? '')
  const returnLink = getMainWebsiteUrl(lang ?? 'fi')

  const order = useTypedSelector(state => state.order.currentOrder)
  const comments =
    order.comments?.length > 0 ? order.comments.filter(comment => comment.comment.length > 0) : []

  const isPartlyConfirmedOrder =
    (order.state === 'ACCEPTED' || order.state === 'AWAITING_PAYMENT') &&
    order.items.some(item => item.metadata.status === 'Declined')
  const activities = order.metadata.activities ?? []
  const [showFailedPaymentDialog, setShowFailedPaymentDialog] = useState(false)
  const currentStatusValues = InitCurrentStatus(order.state)

  const isReadyBePaid = order.state === 'ACCEPTED' || order.state === 'AWAITING_PAYMENT'
  const orderPrice = round(Number(order.total.split(' ')[0]))
  const buttonTextWithPrice =
    order.state === 'ACCEPTED' || order.state === 'AWAITING_PAYMENT'
      ? `${currentStatusValues.buttonText} (${orderPrice})`
      : currentStatusValues.buttonText

  const [cancelOrderByCustomer, { loading: cancelLoading }] = useMutation(CANCEL_CATALOG_ORDER, {
    onCompleted(result) {
      if (result.cancelCatalogOrder) {
        if (result.cancelCatalogOrder.state === 'CANCELLED') {
          refetch({ order: order.id })
        }
      } else {
        dispatch(
          editSnackbar({
            message: t('common:error'),
            type: 'error'
          })
        )
      }
    },
    onError(error) {
      dispatch(
        editSnackbar({
          message: `Error. ${error}`,
          type: 'error'
        })
      )
    }
  })

  const [checkoutCatalogOrder, { loading: checkoutLoading }] = useMutation(CHECKOUT_CATALOG_ORDER, {
    onCompleted(result) {
      if (result.checkoutCatalogOrder) {
        window.location.replace(result.checkoutCatalogOrder.paymentUrl)
      } else {
        dispatch(
          editSnackbar({
            message: t('common:error'),
            type: 'error'
          })
        )
      }
    },
    onError(error) {
      dispatch(
        editSnackbar({
          message: `Error. ${error}`,
          type: 'error'
        })
      )
    }
  })

  const payOrder = () => {
    if (order.state === 'ACCEPTED') {
      checkoutCatalogOrder({
        variables: {
          order: order.id
        }
      })
    }

    if (order.state === 'AWAITING_PAYMENT' && order.paymentUrl) {
      window.location.replace(order.paymentUrl)
    }
  }

  const cancelOrder = () => {
    cancelOrderByCustomer({
      variables: {
        order: order.id
      }
    })
  }

  const getNavigationButtons = () => {
    switch (order.state) {
      case 'AWAITING_REVIEW':
        return [] as FlowNavButtonProps[]
      case 'AWAITING_PAYMENT':
        return order.items.length > 1
          ? ([
              {
                text: buttonTextWithPrice,
                variant: 'contained',
                color: 'secondary',
                onClick: () => {
                  payOrder()
                },
                fullWidth: true
              }
            ] as FlowNavButtonProps[])
          : ([] as FlowNavButtonProps[])
      case 'REJECTED':
        return [
          {
            text: currentStatusValues.buttonText,
            variant: 'contained',
            startIcon: 'Add',
            color: 'secondary',
            onClick: () => {
              if (returnLink) {
                window.location.replace(returnLink)
              }
            },
            fullWidth: true
          }
        ] as FlowNavButtonProps[]
      case 'CANCELLED':
        return [
          {
            text: currentStatusValues.buttonText,
            variant: 'contained',
            startIcon: 'Add',
            color: 'secondary',
            onClick: () => {
              if (returnLink) {
                window.location.replace(returnLink)
              }
            },
            fullWidth: true
          }
        ] as FlowNavButtonProps[]
      case 'ACCEPTED':
        return order.items.length > 1
          ? ([
              {
                text: buttonTextWithPrice,
                variant: 'contained',
                color: 'secondary',
                onClick: () => {
                  payOrder()
                },
                fullWidth: true
              }
            ] as FlowNavButtonProps[])
          : ([] as FlowNavButtonProps[])
      case 'EXPIRED':
        return [
          {
            text: currentStatusValues.buttonText,
            variant: 'contained',
            startIcon: 'Add',
            color: 'secondary',
            onClick: () => {
              if (returnLink) {
                window.location.replace(returnLink)
              }
            },
            fullWidth: true
          }
        ] as FlowNavButtonProps[]
      default:
        return [] as FlowNavButtonProps[]
    }
  }

  const buttons = getNavigationButtons()

  return (
    <Layout
      {...layoutProps}
      bottom={<FlowNav actionDirection="column" actionComponents={buttons} />}
      showFooter
      scrollableContent={true}
    >
      <UqBackdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={loading || cancelLoading || checkoutLoading}
      >
        <UqCircularProgress color="inherit" />
      </UqBackdrop>
      <Image
        src="https://www.apelago.fi/apelago-logo-box.svg"
        width={60}
        center
        marginVertical={24}
      />
      <UqBox style={{ display: 'flex', justifyContent: 'center' }}>
        <UqTypography variant="overline">
          {t('Order:order')} #{orderId}
        </UqTypography>
      </UqBox>
      <UqTypography variant="h6" color="text.primary" align="center" marginBottom={8}>
        {currentStatusValues.title}
      </UqTypography>
      <UqTypography variant="body2" color="text.secondary" align="center">
        {currentStatusValues.description}
      </UqTypography>
      {showFailedPaymentDialog && orderId && (
        <FailedPaymentDialog
          showDialog={showFailedPaymentDialog}
          setShowDialog={setShowFailedPaymentDialog}
          status="confirmed"
          orderId={order.id}
        />
      )}
      {isReadyBePaid && (
        <UqBox marginTop={16}>
          <UqAlert severity="info">
            <UqAlertTitle>{t('Order:stripeCheckoutTitle')}</UqAlertTitle>
            {t('Order:stripeCheckoutInfoText')}
          </UqAlert>
          <UqButton
            variant="contained"
            color="secondary"
            fullWidth
            marginTop={24}
            onClick={payOrder}
          >
            {t('Main:payNow')} ({orderPrice})
          </UqButton>
          {isPartlyConfirmedOrder && (
            <UqButton variant="text" color="error" fullWidth marginTop={8} onClick={cancelOrder}>
              {t('Order:cancelOrder')}
            </UqButton>
          )}
        </UqBox>
      )}
      {order.state === 'PAID' && (
        <UqBox marginTop={16}>
          <RWebShare
            data={{
              title: `A'Pelago order #${order.id} details`,
              text: 'You can check the order details by the link below.',
              url: `${process.env.REACT_APP_BASE_URL}/${lang}/order/${order.id}`
            }}
          >
            <UqButton
              variant="contained"
              color="primary"
              fullWidth
              marginTop={24}
              startIcon={<ShareIcon />}
            >
              {t('Order:shareOrderDetails')}
            </UqButton>
          </RWebShare>
          <UqButton
            variant="text"
            color="primary"
            fullWidth
            marginTop={8}
            startIcon={<AddIcon />}
            href={process.env.REACT_APP_MAIN_WEBSITE_URL_FI}
          >
            {t('Order:makeNewOrder')}
          </UqButton>
        </UqBox>
      )}
      <UqDivider marginVertical={24} />
      {comments?.map((comment, index) => (
        <UqBox marginTop={16} marginBottom={16} key={index}>
          <OrderComment comment={comment.comment} provider={comment.user.title ?? ''} />
        </UqBox>
      ))}
      {order.state !== 'REJECTED' && (
        <OrderProducts products={order.items} activitiesInCart={activities} />
      )}
    </Layout>
  )
}

export default OrderStatusPage
