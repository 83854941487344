import { createSlice } from '@reduxjs/toolkit'
import { ProductInCart } from './CartSlice'

export type CurrentOrderItem = {
  quantity: number
  sku: string
  tax: string | number | null
  title: string
  totalPrice: string
  unitPrice: string
  accepted: boolean
  metadata: {
    category: string
    endDate: string
    endDateString: string
    numericPrice: number
    pax: number
    productName: string
    startDate: string
    startDateString: string
    vatPercent: number
    status: string
    serviceProvider: string
    serviceProviderId: string
    serviceProviderEmail: string
    unit: string
  }
}

type Comment = {
  byCustomer: boolean
  comment: string
  user: {
    id: string
    email: string
    title: string
  }
}

export type CartProductToOrder = {
  sku: string
  quantity: number
  metadata: {
    productName: string
    vatPercent: number
    numericPrice: number
    category: string
    pax: number
    unit: string
    startDate: string
    startDateString: string
    endDate: string
    endDateString: string
    status: string
    serviceProvider: string
    days: number
    nights: number
    serviceProviderId: string
    serviceProviderEmail: string
  }
}

type OrderSchema = {
  status: null | 'draft' | 'created'
  isConfirmedRule: boolean
  preFilledOrderRequest: {
    startDate: string
    endDate: string
    guests: 0
  }
  preOrder: {
    id: string
    items: ProductInCart[]
  }
  currentOrder: {
    catalog: string
    id: string
    items: CurrentOrderItem[]
    paymentUrl: string | null
    state: string
    total: string
    comments: Comment[]
    metadata: {
      businessId: string
      companyAddress: string
      companyCity: string
      companyCountry: string
      companyName: string
      companyZip: string
      contactEmail: string
      contactFirstName: string
      contactLastName: string
      contactPhone: string
      isSameContactPerson: boolean
      language: string
      orderEmail: string
      orderFirstName: string
      orderLastName: string
      orderPhone: string
      taxAmount: string
      serviceProviderComment: string
      unit: string
      activities: CartProductToOrder[]
    }
  }
}

const initialState: OrderSchema = {
  status: null,
  isConfirmedRule: false,
  preOrder: {
    id: '',
    items: []
  },
  preFilledOrderRequest: {
    startDate: '',
    endDate: '',
    guests: 0
  },
  currentOrder: {
    catalog: '',
    id: '',
    items: [],
    paymentUrl: null,
    state: '',
    total: '',
    comments: [],
    metadata: {
      businessId: '',
      companyAddress: '',
      companyCity: '',
      companyCountry: '',
      companyName: '',
      companyZip: '',
      contactEmail: '',
      contactFirstName: '',
      contactLastName: '',
      contactPhone: '',
      isSameContactPerson: false,
      language: '',
      orderEmail: '',
      orderFirstName: '',
      orderLastName: '',
      orderPhone: '',
      taxAmount: '',
      serviceProviderComment: '',
      unit: '',
      activities: []
    }
  }
}

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    updateStatus: (state, action) => {
      state.status = action.payload.status
    },
    confirmRule: (state, action) => {
      state.isConfirmedRule = action.payload.isConfirmedRule
    },
    setPreFilledOrderRequest: (state, action) => {
      state.preFilledOrderRequest = action.payload.preFilledOrderRequest
    },
    setPreOrder: (state, action) => {
      state.preOrder = action.payload.preOrder
    },
    setCurrentOrder: (state, action) => {
      state.currentOrder = action.payload.order
    },
    addComment: (state, action) => {
      state.currentOrder.metadata.serviceProviderComment = action.payload.comment
    }
  }
})

export const {
  updateStatus,
  confirmRule,
  setPreFilledOrderRequest,
  setPreOrder,
  setCurrentOrder,
  addComment
} = orderSlice.actions
export default orderSlice.reducer
