import React, { useState, Dispatch, SetStateAction, useEffect } from 'react'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import { Layout, Image, Add, FlowNav } from '@uniqore/module'
import {
  UqBox,
  UqTypography,
  UqIconButton,
  UqDivider,
  UqDatePicker,
  UqTextField,
  UqTextFieldProps,
  UqButton
} from '@uniqore/wrapper'
import AppBarMenu from 'Components/Common/AppBarMenu'
import { ProductWithQuantity } from 'Types/Common'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { layoutProps } from 'Theme'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { round } from 'Components/Cart/Helpers/RoundPrice'
import { useParams } from 'react-router-dom'
import { fi, enGB, sv } from 'date-fns/locale'
import useTypedSelector from 'Store'
import { CATEGORIES } from 'App'
import { addBookingInfo, removeProduct } from 'Features/CartSlice'
import { useDispatch } from 'react-redux'
import { format } from 'date-fns'
import {
  getFormmattedDateFromDayMonthYear,
  getFormmattedDateFromMonthDayYear
} from 'Util/getDatesNightsBetween'
import { getDiff, getDates } from 'Util/dateFunctions'
import imagePlaceholder from 'Assets/apelago-uniqore-placeholder.png'
import { getSpacePricePerDay, getAccommodationPricePerNight } from 'Util/productPageCalculations'
import { ProductPageTags } from 'Components/Product/ProductPageTags'
import { LongDescriptionSection } from 'Components/Product/LongDescriptionSection'
import { PricePerUnitSection } from 'Components/Product/PricePerUnitSection'

const localeMap = {
  fi: fi,
  en: enGB,
  sw: sv
}

interface ProductPageProps {
  setShowProduct: Dispatch<SetStateAction<boolean>>
  product: ProductWithQuantity
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatchReducer: React.Dispatch<any>
  activeProductQuantity: number
}

const ProductPage = (props: ProductPageProps) => {
  const { setShowProduct, product, dispatchReducer, activeProductQuantity } = props
  const dispatch = useDispatch()
  const { lang } = useParams()

  const { t } = useTranslation(['Main', 'Cart'])

  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [lang])

  const translations = useTypedSelector(state => state.translations)
  const cartProducts = useTypedSelector(state => state.cartSlice.products)
  const productInCart = cartProducts.find(p => p.productId === product.id)
  const preFilledOrderRequest = useTypedSelector(state => state.order.preFilledOrderRequest)

  const [arrivingDate, setArrivingDate] = useState(
    productInCart?.cartMeta.startDate
      ? new Date(getFormmattedDateFromDayMonthYear(productInCart?.cartMeta.startDate))
      : new Date(getFormmattedDateFromMonthDayYear(preFilledOrderRequest.startDate))
  )

  const [leavingDate, setLeavingDate] = useState(
    productInCart?.cartMeta.endDate
      ? new Date(getFormmattedDateFromDayMonthYear(productInCart?.cartMeta.endDate))
      : new Date(getFormmattedDateFromMonthDayYear(preFilledOrderRequest.endDate))
  )
  const [guests, setGuests] = useState<number>(
    productInCart?.cartMeta.guests ?? preFilledOrderRequest?.guests
  )

  const [longDescReadMoreOpen, setLongDescReadMoreOpen] = useState<boolean>(false)
  const [showAppBar, setShowAppBar] = useState<boolean>(false)
  const longDescValues = { moreText: 'Read more', lessText: 'Read less' }
  const productWithArrivingLeavingDates =
    product.metadata.category === CATEGORIES.CoWorkingSpaces ||
    product.metadata.category === CATEGORIES.Accommodation

  const removeProductFromCart = () => {
    dispatch(removeProduct({ productId: product.id }))
  }

  const handleCloseProductPage = () => {
    const productToSave = product

    if (productToSave) {
      if (productInCart) {
        dispatchReducer({
          type: 'setQuantity',
          payload: {
            id: productToSave.id,
            value: productInCart.productQuantity
          }
        })
      }
      if (productWithArrivingLeavingDates) {
        dispatch(
          addBookingInfo({
            productId: productToSave.id,
            startDate: format(arrivingDate, 'dd.MM.yyyy'),
            endDate: format(leavingDate, 'dd.MM.yyyy'),
            guests:
              productToSave.metadata.pax * activeProductQuantity >= preFilledOrderRequest.guests
                ? productToSave.metadata.category === CATEGORIES.CoWorkingSpaces
                  ? guests
                  : preFilledOrderRequest.guests
                : productToSave.metadata.pax * activeProductQuantity
          })
        )
      }
    }
  }

  const closeProductPageWithoutSaving = () => {
    const productToSave = product

    if (productToSave) {
      if (productInCart) {
        dispatchReducer({
          type: 'setQuantity',
          payload: {
            id: productToSave.id,
            value: productToSave.quantity
          }
        })
      }
    }
    setShowProduct(false)
  }

  const conditionsForUpdateOtherProducts =
    (product.quantity !== activeProductQuantity && product?.quantity !== 0) ||
    productInCart?.cartMeta.startDate !== format(arrivingDate, 'dd.MM.yyyy') ||
    productInCart?.cartMeta.endDate !== format(leavingDate, 'dd.MM.yyyy')

  const conditionsForUpdateSpace =
    conditionsForUpdateOtherProducts || productInCart?.cartMeta.guests !== guests

  const isReadyToBeUpdated =
    product.metadata.category === CATEGORIES.CoWorkingSpaces
      ? conditionsForUpdateSpace
      : conditionsForUpdateOtherProducts
  const isReadyToBeRemoved =
    product.quantity === activeProductQuantity && activeProductQuantity !== 0 && !isReadyToBeUpdated

  const getPrice = () => {
    if (product.metadata.category === CATEGORIES.CoWorkingSpaces) {
      const diff = getDiff(arrivingDate, leavingDate)

      const days = Math.floor(diff / 86400000) + 1
      const pricePerDay = getSpacePricePerDay(days, product)
      if (product.metadata.type === 'Open office' || product.metadata.type === 'Separate office') {
        return round(activeProductQuantity * pricePerDay * days * guests)
      }
      return round(activeProductQuantity * pricePerDay * days)
    }
    if (product.metadata.category === CATEGORIES.Accommodation) {
      const diff = getDiff(arrivingDate, leavingDate)
      const nights = Math.floor(diff / 86400000)
      const dates = getDates(arrivingDate, leavingDate)
      const pricePerNight = getAccommodationPricePerNight(nights, dates, product)
      return round(activeProductQuantity * pricePerNight * nights)
    }
    return round(activeProductQuantity * product.variants[0].metadata.numericPrice)
  }

  const longDescription = product.metadata.longDescription

  const translatedLongDescription =
    longDescription && translations[longDescription]
      ? lang === 'en'
        ? translations[longDescription].key
        : lang === 'fi'
        ? translations[longDescription].fi
        : translations[longDescription].sv
      : longDescription

  const translatedName = translations[product.name]
    ? lang === 'en'
      ? translations[product.name].key
      : lang === 'fi'
      ? translations[product.name].fi
      : translations[product.name].sv
    : product.name

  const [maxDate, setMaxDate] = useState(new Date())

  useEffect(() => {
    const newDate = new Date(arrivingDate)
    newDate.setMonth(newDate.getMonth() + 5)
    setMaxDate(newDate)
  }, [arrivingDate])

  const imageUrl = product.metadata.images?.length > 0 ? product.metadata.images[0]?.url : ''

  const setLeavingInputDate = (date: Date) => {
    if (product.metadata.category === CATEGORIES.CoWorkingSpaces) {
      if (Math.round(date.getTime() / 1000) >= Math.round(arrivingDate.getTime() / 1000)) {
        setLeavingDate(date)
      } else {
        setLeavingDate(arrivingDate)
      }
    }

    if (product.metadata.category === CATEGORIES.Accommodation) {
      if (Math.round(date.getTime() / 1000) > Math.round(arrivingDate.getTime() / 1000)) {
        setLeavingDate(date)
      } else {
        const followingDay = new Date(arrivingDate.getTime() + 86400000) // + 1 day in msDate(date.getDate() + 1)
        setLeavingDate(followingDay)
      }
    }
  }

  return (
    <Layout
      id="productLayout"
      {...layoutProps}
      top={<Image src={imageUrl ? imageUrl : imagePlaceholder} />}
      onScroll={(e: React.UIEvent<HTMLElement>) => {
        const scrollAmount: number = e.currentTarget.scrollTop
        const standardImageHieght = 260
        if (scrollAmount > standardImageHieght) {
          setShowAppBar(true)
        } else {
          setShowAppBar(false)
        }
      }}
      topSticky={
        <>
          <AppBarMenu
            title={translatedName}
            color="inherit"
            isProductPage={true}
            showBottomBorder={showAppBar}
            setShowProduct={setShowProduct}
            sx={{
              height: showAppBar ? 64 : 0,
              transform: !showAppBar ? 'translateY(-56px)' : '',
              visibility: showAppBar ? 'visible' : 'hidden',
              borderBottom: 0
            }}
          ></AppBarMenu>
          <UqIconButton
            sx={{
              background: '#fff',
              '&:hover': {
                background: '#80808050'
              }
            }}
            style={{
              position: 'absolute',
              right: '14px',
              top: '14px',
              zIndex: 1000,
              borderRadius: '100%'
            }}
            onClick={() => {
              closeProductPageWithoutSaving()
            }}
          >
            <CloseRoundedIcon />
          </UqIconButton>
        </>
      }
      scrollableContent={true}
      bottomSticky={
        <FlowNav
          actionComponents={[
            <Add
              color="secondary"
              key={1}
              variant="multi"
              id={product.id}
              quantity={activeProductQuantity}
              dispatch={dispatchReducer}
              alwaysOpen
              hideRemoveIcon
            />,
            isReadyToBeRemoved ? (
              <UqButton
                key={3}
                fullWidth
                variant="contained"
                style={{ backgroundColor: '#e11212' }}
                onClick={() => {
                  dispatchReducer({
                    type: 'remove',
                    payload: {
                      id: product.id
                    }
                  })
                  removeProductFromCart()
                  setShowProduct(false)
                }}
              >
                {t('Main:removeAll')}
              </UqButton>
            ) : (
              <UqButton
                key={2}
                fullWidth
                variant="contained"
                onClick={() => {
                  handleCloseProductPage()
                  setShowProduct(false)
                }}
              >
                {isReadyToBeUpdated
                  ? `${t('Main:updateOrder')} (${getPrice()})`
                  : `${t('Main:add')} (${getPrice()})`}
              </UqButton>
            )
          ]}
        />
      }
    >
      <UqBox marginTop={16}>
        <UqTypography variant="h6" color="text.primary">
          {translatedName}
        </UqTypography>
        <PricePerUnitSection
          product={product}
          translations={translations}
          arrivingDate={arrivingDate}
          leavingDate={leavingDate}
          lang={lang ?? 'fi'}
        />
        {product.description && (
          <UqTypography variant="body2" color="text.secondary">
            {translations[product.description]
              ? lang === 'en'
                ? translations[product.description].key
                : lang === 'fi'
                ? translations[product.description].fi
                : translations[product.description].sv
              : product.description}
          </UqTypography>
        )}
        <LongDescriptionSection
          longDescReadMoreOpen={longDescReadMoreOpen}
          translatedLongDescription={translatedLongDescription}
          longDescValues={longDescValues}
          setLongDescReadMoreOpen={setLongDescReadMoreOpen}
        />
        <ProductPageTags product={product} translations={translations} lang={lang ?? 'fi'} />
      </UqBox>
      <UqDivider variant="fullWidth" marginVertical={16} />
      {product.metadata.category !== CATEGORIES.FoodAndBeverages && (
        <>
          <UqTypography variant="overline">{t('Main:stayDates')}</UqTypography>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={localeMap[(lang as 'fi' | 'en' | 'sw') || 'en']}
          >
            <UqDatePicker
              label={t('Main:arriving')}
              value={arrivingDate}
              onChange={date => {
                setArrivingDate(date)
                if (Math.round(date.getTime() / 1000) >= Math.round(leavingDate.getTime() / 1000)) {
                  setLeavingDate(date)
                }
              }}
              inputFormat="dd.MM.yyyy"
              disablePast
              renderInput={(params: UqTextFieldProps) => (
                <UqTextField {...params} fullWidth marginTop={24} marginBottom={4} />
              )}
            />
            <UqDatePicker
              label={t('Main:leaving')}
              value={leavingDate}
              onChange={date => setLeavingInputDate(date)}
              inputFormat="dd.MM.yyyy"
              disablePast
              maxDate={maxDate}
              renderInput={(params: UqTextFieldProps) => (
                <UqTextField {...params} fullWidth marginTop={24} marginBottom={4} />
              )}
            />
          </LocalizationProvider>
          {product.metadata.category === CATEGORIES.CoWorkingSpaces && (
            <UqTextField
              label={t('Main:Guests')}
              fullWidth
              marginTop={16}
              value={guests}
              type="number"
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 0 }}
              onChange={e => {
                if (Number(e.target.value) <= product.metadata.pax) {
                  setGuests(Number(e.target.value))
                }
              }}
            />
          )}
          {product.metadata.category === CATEGORIES.CoWorkingSpaces ||
            (product.metadata.category === CATEGORIES.Accommodation && (
              <UqTypography variant="body1" color="text.primary" marginTop={16}>
                {t('Main:capacity')}: {product.metadata.pax} {t('Main:guests')}
              </UqTypography>
            ))}
        </>
      )}
    </Layout>
  )
}

export default ProductPage
