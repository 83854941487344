import { UqBox, UqList, UqListItem, UqListItemText, UqTypography } from '@uniqore/wrapper'
import { CATEGORIES } from 'App'
import { CurrentOrderItem, CartProductToOrder } from 'Features/OrderSlice'
import { t } from 'i18next'
import { useParams } from 'react-router-dom'
import useTypedSelector from 'Store'
import { round } from './Helpers/RoundPrice'
import { getTranslatedItemName } from 'Util/translations'

interface ProductsForConfirmationProps {
  products: CurrentOrderItem[]
  activitiesInCart: CartProductToOrder[]
}

const ProductsForConfirmation = (props: ProductsForConfirmationProps) => {
  const { products, activitiesInCart } = props
  const { lang } = useParams()
  const translations = useTypedSelector(state => state.translations)

  const getTranslatedUnit = (item: CurrentOrderItem | CartProductToOrder) => {
    return translations[item.metadata.unit]
      ? lang === 'en'
        ? translations[item.metadata.unit].key
        : lang === 'fi'
        ? translations[item.metadata.unit].fi
        : translations[item.metadata.unit].sv
      : item.metadata.unit
  }

  const getName = (item: CurrentOrderItem) => {
    if (item.metadata.category === CATEGORIES.FoodAndBeverages) {
      return `${item.quantity} x ${getTranslatedItemName(
        item,
        lang ?? 'fi',
        translations
      )} / ${getTranslatedUnit(item).toLowerCase()}`
    }
    return `${item.quantity} x ${getTranslatedItemName(item, lang ?? 'fi', translations)}`
  }
  return (
    <>
      {products.map(item => {
        return (
          <UqBox
            marginBottom={12}
            marginTop={12}
            style={{ border: '1px solid #80808040' }}
            key={item.sku}
          >
            <UqList disablePadding={true}>
              <UqListItem>
                <UqListItemText
                  primary={getName(item)}
                  secondary={
                    <>
                      {item.metadata.startDateString && (
                        <UqTypography
                          variant="body2"
                          color="text.secondary"
                          component="span" // needed for validate DOM-nesting
                          sx={{ display: 'block' }} // since component is 'span', set display to block
                        >
                          {item.metadata.startDateString} - {item.metadata.endDateString}
                        </UqTypography>
                      )}
                      {item.metadata.category !== CATEGORIES.Activities && item.metadata.pax > 0 && (
                        <>
                          <UqTypography
                            variant="body2"
                            color="text.secondary"
                            component="span" // needed for validate DOM-nesting
                            sx={{ display: 'block' }} // since component is 'span', set display to block
                          >
                            {`${item.metadata.pax} ${t('Main:guests')}`}
                          </UqTypography>
                        </>
                      )}
                      {item.metadata.numericPrice > 0 && (
                        <UqTypography
                          variant="body2"
                          color="text.secondary"
                          component="span" // needed for validate DOM-nesting
                          sx={{ display: 'block' }} // since component is 'span', set display to block
                        >
                          {round(item.metadata.numericPrice)}
                        </UqTypography>
                      )}
                    </>
                  }
                  primaryTypographyProps={{ color: 'text.primary' }}
                  secondaryTypographyProps={{ color: 'text.secondary' }}
                />
              </UqListItem>
            </UqList>
          </UqBox>
        )
      })}
      {activitiesInCart.map(item => {
        return (
          <UqBox
            marginBottom={12}
            marginTop={12}
            style={{ border: '1px solid #80808040' }}
            key={item.sku}
          >
            <UqList disablePadding={true}>
              <UqListItem>
                <UqListItemText
                  primary={getTranslatedItemName(item, lang ?? 'fi', translations)}
                  primaryTypographyProps={{ color: 'text.primary' }}
                  secondaryTypographyProps={{ color: 'text.secondary' }}
                />
              </UqListItem>
            </UqList>
          </UqBox>
        )
      })}
    </>
  )
}

export default ProductsForConfirmation
