import { useEffect, useState } from 'react'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import {
  UqBox,
  UqList,
  UqListItem,
  UqListItemText,
  UqTypography,
  UqListItemSecondaryAction,
  UqButton,
  UqIconButton,
  UqMenu,
  UqMenuItem,
  UqDialog,
  UqDialogActions,
  UqDialogContent,
  UqDialogContentText,
  UqDialogTitle
} from '@uniqore/wrapper'
import AddIcon from '@mui/icons-material/Add'
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded'
import useTypedSelector from 'Store'
import { CATEGORIES, URLS } from 'App'
import { useNavigate, useParams } from 'react-router-dom'
import { ProductInCart } from 'Features/CartSlice'
import { round } from 'Components/Cart/Helpers/RoundPrice'
import { getMainWebsiteUrl } from 'Util'

interface BookedSpaceSection {
  spaces: ProductInCart[]
}

const BookedSpaceSection = (props: BookedSpaceSection) => {
  const { spaces } = props
  const allProducts = useTypedSelector(state => state.airtableData.products)
  const allSpaces = allProducts.filter(
    product => product.metadata.category === CATEGORIES.CoWorkingSpaces
  )
  const navigate = useNavigate()
  const { lang } = useParams()

  const { t } = useTranslation(['Main', 'Cart'])

  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [lang])
  const serviceProvider = useTypedSelector(state => state.airtableData.serviceProvider)

  const returnLink = getMainWebsiteUrl(lang ?? 'fi')

  const translations = useTypedSelector(state => state.translations)

  const navigateToProduct = (spaceId: string) => {
    navigate(`/${lang}/${URLS.SpacePage}/${serviceProvider.id}/${spaceId}`)
  }

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const getPrice = (space: ProductInCart) => {
    return round(space.cartMeta.price)
  }

  return (
    <>
      <UqList disablePadding={true}>
        <UqListItem>
          <UqListItemText
            primary={t('Main:coWorkingSpace')}
            secondary={
              typeof serviceProvider?.fields.Name === 'string' &&
              translations[serviceProvider.fields.Name]
                ? lang === 'en'
                  ? translations[serviceProvider.fields.Name].key
                  : lang === 'fi'
                  ? translations[serviceProvider.fields.Name].fi
                  : translations[serviceProvider.fields.Name].sv
                : serviceProvider?.fields.Name
            }
            primaryTypographyProps={{ color: 'text.primary' }}
            secondaryTypographyProps={{ color: 'text.secondary' }}
          />
          <UqListItemSecondaryAction>
            <UqIconButton
              edge="end"
              color="secondary"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              <MoreHorizRoundedIcon />
            </UqIconButton>
            <UqMenu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button'
              }}
            >
              <UqMenuItem onClick={() => setShowDialog(true)}>Remove</UqMenuItem>
            </UqMenu>
          </UqListItemSecondaryAction>
        </UqListItem>
      </UqList>
      <UqDialog open={showDialog} fullWidth>
        <UqDialogTitle>{t('Main:noProductsDialogTitle')}</UqDialogTitle>
        <UqDialogContent>
          <UqDialogContentText>{t('Main:removeSpaceDialogText')}</UqDialogContentText>
        </UqDialogContent>
        <UqDialogActions style={{ marginBottom: 8 }}>
          <UqButton onClick={() => setShowDialog(false)}>{t('Main:goBack')}</UqButton>
          <UqButton
            onClick={() => returnLink && window.location.replace(returnLink)}
            variant="contained"
          >
            {t('Main:yesContinue')}
          </UqButton>
        </UqDialogActions>
      </UqDialog>
      <UqBox
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          position: 'relative'
        }}
        marginHorizontal={16}
        marginTop={8}
      >
        {spaces.map(space => (
          <UqBox
            key={space.productId}
            backgroundColor="#fdfbf5"
            style={{ border: '1px solid #80808040' }}
            marginBottom={allSpaces?.length > 1 ? 8 : 16}
          >
            <UqList disablePadding={true}>
              <UqListItem>
                <UqListItemText
                  primary={
                    translations[space.cartMeta.name]
                      ? lang === 'en'
                        ? translations[space.cartMeta.name].key
                        : lang === 'fi'
                        ? translations[space.cartMeta.name].fi
                        : translations[space.cartMeta.name].sv
                      : space.cartMeta.name
                  }
                  secondary={
                    <>
                      <UqTypography
                        variant="body2"
                        color="text.secondary"
                        component="span" // needed for validate DOM-nesting
                        sx={{ display: 'block' }} // since component is 'span', set display to block
                      >
                        {space.cartMeta.startDate} - {space.cartMeta.endDate}
                      </UqTypography>
                      <UqTypography
                        variant="body2"
                        color="text.secondary"
                        component="span" // needed for validate DOM-nesting
                        sx={{ display: 'block' }} // since component is 'span', set display to block
                      >
                        {space.cartMeta.guests} {t('Main:guests')}
                      </UqTypography>
                      <UqTypography
                        variant="body2"
                        color="text.secondary"
                        component="span" // needed for validate DOM-nesting
                        sx={{ display: 'block' }} // since component is 'span', set display to block
                      >
                        {getPrice(space)}
                      </UqTypography>
                    </>
                  }
                  primaryTypographyProps={{ color: 'text.primary' }}
                  secondaryTypographyProps={{ color: 'text.secondary' }}
                />
                <UqListItemSecondaryAction>
                  <UqButton
                    color="secondary"
                    variant="text"
                    style={{ minWidth: 48, marginRight: -15 }}
                    onClick={() => navigateToProduct(space.productId)}
                  >
                    {t('Main:edit')}
                  </UqButton>
                </UqListItemSecondaryAction>
              </UqListItem>
            </UqList>
          </UqBox>
        ))}
        {allSpaces.length > 1 && (
          <UqBox style={{ display: 'flex', alignItems: 'center' }} marginBottom={16}>
            <UqButton
              variant="text"
              color="secondary"
              startIcon={<AddIcon />}
              onClick={() => navigate(`/${lang}/${URLS.SpacePage}/${serviceProvider.id}`)}
            >
              {t('Main:addNew')}
            </UqButton>
          </UqBox>
        )}
      </UqBox>
    </>
  )
}

export default BookedSpaceSection
