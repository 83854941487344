import { createTheme } from '@mui/material/styles'
import { UqTheme } from '@uniqore/theme'

export const layoutProps = {
  containerBackground: '#eaeaea',
  borderRadius: '0px',
  borderWidth: '1px',
  borderColor: '#2F2E2E38',
  useElevation: false
}

const theme = createTheme({
  ...UqTheme,
  palette: {
    primary: {
      main: '#002444',
      dark: '#00001e',
      light: '#334b6f',
      contrastText: '#fff'
    },
    secondary: {
      main: '#e0af34',
      dark: '#aa8000',
      light: '#ffe166',
      contrastText: 'rgba(0, 0, 0, 0.87)'
    },
    error: {
      main: '#EA4274',
      dark: '#b20049',
      light: '#ff78a2',
      contrastText: '#fff'
    },
    warning: {
      main: '#FF993F',
      dark: '#c76a07',
      light: '#ffca6e',
      contrastText: 'rgba(0, 0, 0, 0.87)'
    },
    info: {
      main: '#44ADFF',
      dark: '#007ecb',
      light: '#83dfff',
      contrastText: 'rgba(0, 0, 0, 0.87)'
    },
    success: {
      main: '#76D275',
      dark: '#43a047',
      light: '#a9ffa5',
      contrastText: 'rgba(0, 0, 0, 0.87)'
    },
    background: {
      default: '#ffffff',
      paper: '#ffffff'
    },
    text: {
      primary: '#252e2e',
      secondary: '#252e2e99',
      disabled: '#252e2e61'
    }
  },
  typography: {
    h5: {
      fontWeight: 500
    },
    h6: {
      fontWeight: 500
    },
    button: {
      textTransform: 'none',
      fontWeight: 500
    },
    overline: {
      fontWeight: 700
    }
  },
  custom: {
    borderColor: '#E6EAEB'
  }
})

// Custom values for theme if needed, just an example
declare module '@mui/material/styles' {
  interface Theme {
    custom: {
      borderColor: string
    }
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    custom: {
      borderColor: string
    }
  }
}

export default theme
