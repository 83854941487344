import { useEffect, useState } from 'react'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import { Layout, Image, FlowNav } from '@uniqore/module'
import { UqTypography, UqTextField, UqButton, UqCircularProgress } from '@uniqore/wrapper'
import { layoutProps } from 'Theme'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ServiceProviderURLS } from 'App'
import { REQUEST_OTP_LINK, SEND_OTP_CODE } from 'GraphQL/mutations'
import { useMutation } from '@apollo/client'
import AppBarMenu from 'Components/Common/AppBarMenu'

const ServiceProviderOtpPage = () => {
  useEffect(() => {
    if (localStorage.getItem('uq_api_token')) {
      localStorage.removeItem('uq_api_token')
    }
  }, [])

  const navigate = useNavigate()
  const { lang } = useParams()

  const { t } = useTranslation(['Main', 'ServiceProvider'])

  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [lang])

  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const code = params.get('code')
  const path = params.get('path')
  const [isRequested, setIsRequested] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [token, setToken] = useState<string>('')

  const [login, { loading: loginLoading }] = useMutation(SEND_OTP_CODE, {
    onCompleted(mutationResult) {
      if (mutationResult && !mutationResult.otpLogin.error) {
        window.localStorage.setItem('uq_api_token', mutationResult.otpLogin.token)
        setErrorMessage('')
        setToken(mutationResult.otpLogin.token)
      }
    },
    onError(mutationError) {
      if (mutationError) {
        setErrorMessage(t('Main:error'))
      }
    }
  })

  useEffect(() => {
    if (code) {
      login({
        variables: {
          code: code,
          organization: process.env.REACT_APP_ORGANIZATION_ID
        }
      })
    }
  }, [])

  const [loginRequest, { loading }] = useMutation(REQUEST_OTP_LINK, {
    onCompleted(mutationResult) {
      if (mutationResult && !mutationResult.requestOtpLink.error) {
        setIsRequested(true)
      }
    },
    onError(mutationError) {
      if (mutationError) {
        setErrorMessage(t('Main:errorInputEmail'))
      }
    }
  })

  const sendCode = () => {
    loginRequest({
      variables: {
        email: email,
        type: 'ORGUSER',
        organization: process.env.REACT_APP_ORGANIZATION_ID,
        lang: lang,
        path: localStorage.getItem('apelagoOrderId') ?? ''
      }
    })
  }
  const getBack = () => setIsRequested(false)

  useEffect(() => {
    if (token && path) {
      navigate(`/${lang}/${ServiceProviderURLS.ConfirmRequestPage}/${path}`)
    }
  }, [token])

  const isCheckingCode = !token && !isRequested && code
  return (
    <Layout
      {...layoutProps}
      top={<AppBarMenu title={t('ServiceProvider:login')} />}
      showFooter
      bottom={
        isCheckingCode && (
          <FlowNav
            actionDirection="row"
            actionComponents={[
              {
                text: t('ServiceProvider:nothingHappensButtonText'),
                variant: 'text',
                color: 'primary',
                onClick: () => {
                  navigate(`/${lang}/login`)
                },
                fullWidth: true
              }
            ]}
          />
        )
      }
    >
      {isCheckingCode ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            height: '60vh'
          }}
        >
          <UqCircularProgress size={100} color="primary" marginBottom={128} />
          <UqTypography variant="body1" color="text.secondary">
            {t('ServiceProvider:pleaseWait')}
          </UqTypography>
        </div>
      ) : (
        <>
          <Image
            src="https://www.apelago.fi/apelago-logo-box.svg"
            width={60}
            center
            marginBottom={24}
            marginTop={72}
          />
          <UqTypography variant="h6" color="text.primary" align="center">
            {isRequested ? t('ServiceProvider:emailLinkSent') : t('ServiceProvider:login')}
          </UqTypography>
          <UqTypography
            variant="body1"
            color="text.secondary"
            align="center"
            style={{ marginBottom: 48 }}
          >
            {isRequested
              ? t('ServiceProvider:emailLinkSentDescription')
              : t('ServiceProvider:loginDescription')}
          </UqTypography>
          {isRequested && !code ? (
            <>
              <UqButton variant="text" fullWidth color="secondary" onClick={getBack}>
                {loginLoading ? (
                  <UqCircularProgress size={24} />
                ) : (
                  t('ServiceProvider:noReceiviedEmailButtonText')
                )}
              </UqButton>
            </>
          ) : (
            <>
              <UqTextField
                label="Email"
                autoComplete="email"
                fullWidth
                marginVertical={16}
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
              <UqButton variant="contained" fullWidth color="secondary" onClick={sendCode}>
                {loading ? <UqCircularProgress size={24} /> : t('ServiceProvider:sendLink')}
              </UqButton>
              {errorMessage && (
                <UqTypography variant="body1" color="error.main">
                  {errorMessage}
                </UqTypography>
              )}
            </>
          )}
        </>
      )}
    </Layout>
  )
}

export default ServiceProviderOtpPage
