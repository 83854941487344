import { FC, useState, useEffect } from 'react'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import { Layout, FlowNav } from '@uniqore/module'
import {
  UqTypography,
  UqBox,
  UqButton,
  UqDialog,
  UqDialogTitle,
  UqDialogContent,
  UqDialogContentText,
  UqDialogActions,
  UqTextField,
  UqBackdrop,
  UqCircularProgress
} from '@uniqore/wrapper'
import { layoutProps } from 'Theme'
import AppBarMenu from 'Components/Common/AppBarMenu'
import { useNavigate, useParams } from 'react-router-dom'
import { CATEGORIES, ServiceProviderURLS } from 'App'
import AddIcon from '@mui/icons-material/Add'
import useTypedSelector from 'Store'
import { useOrder } from 'Util'
import ProductsForConfirmation from 'Components/Cart/ProductsForConfirmation'
import { CHANGE_CATALOG_ORDER, COMMENT_CATALOG_ORDER } from 'GraphQL/mutations'
import { GET_ORG_USER } from 'GraphQL/queries'
import { useDispatch } from 'react-redux'
import { editSnackbar } from 'Features/snackbar'
import { addComment } from 'Features/OrderSlice'
import { useMutation, useQuery } from '@apollo/client'
import ServiceClient from 'Api/serviceClient'
import { checkUniqureProviders } from 'Util'

const ConfirmRequestPage: FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { lang, id } = useParams()

  const { t } = useTranslation(['Main', 'ServiceProvider', 'Order'])

  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [lang])

  const { data, error: fetchingError } = useQuery(GET_ORG_USER)

  if (fetchingError) {
    navigate(`/${lang}/login`)
  }

  const orgUserEmail = data?.thisUser.email
  const companyName = data?.thisUser.title ?? ''

  const orderId = id
  const { loading, refetch } = useOrder(orderId ?? '')

  const order = useTypedSelector(state => state.order.currentOrder)
  const activities = order.metadata.activities
    ? order.metadata.activities.filter(
        activity => activity.metadata.serviceProviderEmail === orgUserEmail
      )
    : []

  const serviceProviderEmails = order.items.map(product => product.metadata.serviceProviderEmail)
  const uniqueProviderEmails = serviceProviderEmails.filter(checkUniqureProviders)

  const isSingleProvider = uniqueProviderEmails.length === 1

  const providerItems = order.items.filter(
    item => item.metadata.serviceProviderEmail === orgUserEmail
  )

  const otherProviderItems = order.items.filter(
    item => item.metadata.serviceProviderEmail !== orgUserEmail
  )

  const otherItemsInWaitingStatus = otherProviderItems.some(
    item => item.metadata.status === 'Waiting'
  )

  const allProviderItemsAccepted =
    providerItems.length > 0
      ? providerItems.every(item => item.metadata.status === 'Confirmed')
      : false
  const allProviderItemsRejected =
    providerItems.length > 0
      ? providerItems.every(item => item.metadata.status === 'Declined')
      : false

  const allOtherItemsAccepted = otherProviderItems.every(
    item => item.metadata.status === 'Confirmed'
  )
  const allOtherItemsRejected = otherProviderItems.every(
    item => item.metadata.status === 'Declined'
  )

  const isReadyBeAccepted = !otherItemsInWaitingStatus && allOtherItemsAccepted
  const isReadyBeRejected = !otherItemsInWaitingStatus && allOtherItemsRejected

  const mainProvider = providerItems.some(
    item => item.metadata.category === CATEGORIES.CoWorkingSpaces
  )

  useEffect(() => {
    if (order.items.length > 0 && (order.state === 'ACCEPTED' || allProviderItemsAccepted)) {
      navigate(`/${lang}/${ServiceProviderURLS.ReservationConfirmedPage}`)
    }

    if (order.items.length > 0 && (order.state === 'REJECTED' || allProviderItemsRejected)) {
      navigate(`/${lang}/${ServiceProviderURLS.ReservationDeclinedPage}`)
    }
  }, [order])

  const [showWarningDialog, setShowWarningDialog] = useState<boolean>(false)
  const [showCommentDialog, setShowCommentDialog] = useState<boolean>(false)
  const [comment, setComment] = useState<string>('')
  const [commentInputValue, setCommentInputValue] = useState<string>(comment)

  const saveComment = () => {
    setComment(commentInputValue)
    setShowCommentDialog(false)
  }

  const getConvertedItems = (status: string) => {
    return providerItems.map(product => {
      if (product) {
        return {
          sku: product.sku,
          quantity: product.quantity,
          metadata: {
            ...order.metadata,
            status: status
          },
          accepted: status === 'Confirmed' ? true : false
        }
      }
    })
  }

  const [currentAction, setCurrentAction] = useState<'waiting' | 'confirmed' | 'declined'>(
    'waiting'
  )

  const [commentOrder, { loading: commentLoading }] = useMutation(COMMENT_CATALOG_ORDER, {
    onError(error) {
      dispatch(
        editSnackbar({
          message: `Error. ${error}`,
          type: 'error'
        })
      )
    }
  })

  const [changeOrder, { loading: orderLoading }] = useMutation(CHANGE_CATALOG_ORDER, {
    client: ServiceClient,
    onCompleted(result) {
      if (result.changeCatalogOrder) {
        if (comment) {
          commentOrder({
            variables: {
              order: order.id,
              comment: comment
            }
          })
        }
        refetch({ order: order.id })
        if (result.changeCatalogOrder.state === 'ACCEPTED' || allProviderItemsAccepted) {
          if (currentAction === 'confirmed') {
            navigate(`/${lang}/${ServiceProviderURLS.ReservationConfirmedPage}`)
          } else navigate(`/${lang}/${ServiceProviderURLS.ReservationDeclinedPage}`)
        } else if (result.changeCatalogOrder.state === 'REJECTED' || allProviderItemsRejected) {
          navigate(`/${lang}/${ServiceProviderURLS.ReservationDeclinedPage}`)
        }
      } else {
        dispatch(
          editSnackbar({
            message: t('common:error'),
            type: 'error'
          })
        )
      }
    },
    onError(error) {
      dispatch(
        editSnackbar({
          message: `Error. ${error}`,
          type: 'error'
        })
      )
    }
  })

  const declineOrder = () => {
    setCurrentAction('declined')
    if (comment) {
      dispatch(
        addComment({
          comment: comment
        })
      )
    }

    const isMainProviderAcceptedItems = otherProviderItems.some(
      item =>
        item.metadata.category === CATEGORIES.CoWorkingSpaces &&
        item.metadata.status === 'Confirmed'
    )

    const canBeAccepted = !otherItemsInWaitingStatus && isMainProviderAcceptedItems
    const otherStatus = canBeAccepted ? 'ACCEPTED' : 'AWAITING_REVIEW'
    changeOrder({
      variables: {
        order: order.id,
        input: {
          state: isSingleProvider || mainProvider || isReadyBeRejected ? 'REJECTED' : otherStatus,
          items: getConvertedItems('Declined'),
          metadata: {
            ...order.metadata,
            serviceProviderComment: comment
          }
        }
      }
    })
  }

  const acceptOrder = () => {
    setCurrentAction('confirmed')
    if (comment) {
      dispatch(
        addComment({
          comment: comment
        })
      )
    }
    changeOrder({
      variables: {
        order: order.id,
        input: {
          state: isSingleProvider || isReadyBeAccepted ? 'ACCEPTED' : 'AWAITING_REVIEW',
          items: getConvertedItems('Confirmed'),
          metadata: {
            ...order.metadata,
            serviceProviderComment: comment
          }
        }
      }
    })
  }

  return (
    <Layout
      {...layoutProps}
      top={<AppBarMenu title={companyName} color="inherit" showBottomBorder={true}></AppBarMenu>}
      bottom={
        providerItems.length > 0 && (
          <FlowNav
            actionDirection="row"
            actionComponents={[
              {
                text: t('ServiceProvider:declineButtonText'),
                variant: 'text',
                color: 'primary',
                onClick: () => {
                  setShowWarningDialog(true)
                },
                fullWidth: true
              },
              {
                text: t('ServiceProvider:confirmButtonText'),
                variant: 'contained',
                color: 'secondary',
                onClick: () => acceptOrder(),
                fullWidth: true
              }
            ]}
          />
        )
      }
      showFooter
    >
      <UqBackdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={loading || orderLoading || commentLoading}
      >
        <UqCircularProgress color="inherit" />
      </UqBackdrop>
      <UqBox style={{ display: 'flex', justifyContent: 'center' }}>
        <UqTypography variant="overline">
          {t('Order:order')} #{order.id}
        </UqTypography>
      </UqBox>
      <UqTypography variant="h6" color="text.primary" align="center" marginBottom={8}>
        {t('ServiceProvider:confirmReservationTitle')}
      </UqTypography>
      <UqTypography variant="body2" color="text.secondary" align="center">
        {t('ServiceProvider:confirmReservationDescription')}
      </UqTypography>
      <ProductsForConfirmation products={providerItems} activitiesInCart={activities} />
      <UqDialog open={showCommentDialog} fullWidth style={{ maxWidth: '100%' }}>
        <UqDialogTitle>{t('ServiceProvider:commentDialogTitle')}</UqDialogTitle>
        <UqDialogContent>
          <UqDialogContentText>
            <UqTextField
              value={commentInputValue}
              onChange={e => setCommentInputValue(e.target.value)}
              placeholder={t('ServiceProvider:commentDialogPlaceholder')}
              label={t('ServiceProvider:commentDialogLabel')}
              fullWidth
              minRows={3}
              multiline
              marginTop={16}
            />
          </UqDialogContentText>
        </UqDialogContent>
        <UqDialogActions style={{ marginBottom: 8 }}>
          <UqButton
            onClick={() => {
              setCommentInputValue(comment)
              setShowCommentDialog(false)
            }}
          >
            {t('ServiceProvider:commentDialogCloseButton')}
          </UqButton>
          <UqButton onClick={() => saveComment()} variant="contained">
            {t('ServiceProvider:commentDialogSaveButton')}
          </UqButton>
        </UqDialogActions>
      </UqDialog>
      {comment ? (
        <UqBox
          backgroundColor="#fdfbf5"
          style={{
            border: '1px solid #80808040',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <UqTypography
            variant="body2"
            color="text.secondary"
            style={{ paddingTop: 16, marginLeft: 16, marginRight: 16, wordBreak: 'break-word' }}
          >
            {`"${comment}"`}
          </UqTypography>
          <UqButton
            variant="text"
            color="secondary"
            onClick={() => setShowCommentDialog(true)}
            style={{ width: 120 }}
          >
            {t('ServiceProvider:editComment')}
          </UqButton>
        </UqBox>
      ) : (
        <UqBox style={{ display: 'flex', justifyContent: 'center' }}>
          <UqButton
            variant="text"
            color="secondary"
            startIcon={<AddIcon />}
            onClick={() => setShowCommentDialog(true)}
          >
            {t('ServiceProvider:addComment')}
          </UqButton>
        </UqBox>
      )}
      <UqDialog open={showWarningDialog} fullWidth>
        <UqDialogTitle>{t('ServiceProvider:declineOrderDialogTitle')}</UqDialogTitle>
        <UqDialogContent>
          <UqDialogContentText>{t('ServiceProvider:declineOrderDialogText')}</UqDialogContentText>
          <UqTextField
            value={comment}
            onChange={e => setComment(e.target.value)}
            label={t('ServiceProvider:commentDialogLabel')}
            fullWidth
            minRows={3}
            multiline
            marginTop={16}
          />
        </UqDialogContent>
        <UqDialogActions style={{ marginBottom: 8 }}>
          <UqButton
            onClick={() => {
              setCommentInputValue(comment)
              setShowWarningDialog(false)
            }}
          >
            {t('ServiceProvider:declineOrderGoBackButtonText')}
          </UqButton>
          <UqButton onClick={() => declineOrder()} variant="contained">
            {orderLoading ? (
              <UqCircularProgress color="secondary" size={24} />
            ) : (
              t('ServiceProvider:declineButtonText')
            )}
          </UqButton>
        </UqDialogActions>
      </UqDialog>
    </Layout>
  )
}

export default ConfirmRequestPage
