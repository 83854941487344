import { CATEGORIES } from 'App'
import { Product } from 'Types/Common'

export const getIncreasedValue = (item: Product) => {
  const updateItem = JSON.parse(JSON.stringify(item))
  if (updateItem.metadata.category === CATEGORIES.CoWorkingSpaces) {
    if (updateItem.quantity < 1) {
      return { ...updateItem, quantity: (updateItem.quantity += 1) }
    }
    return updateItem
  } else if (updateItem.metadata.category === CATEGORIES.Accommodation) {
    if (updateItem.maxGuests === updateItem.quantity * updateItem.metadata.pax) {
      return updateItem
    } else {
      return { ...updateItem, quantity: (updateItem.quantity += 1) }
    }
  } else {
    return { ...updateItem, quantity: (updateItem.quantity += 1) }
  }
}

export const getDecreasedValue = (item: Product) => {
  const updateItem = JSON.parse(JSON.stringify(item))
  return { ...updateItem, quantity: (updateItem.quantity -= 1) }
}

interface ReducerAction {
  type: 'increase' | 'decrease' | 'remove' | 'setQuantity'
  payload: {
    id: string | number
    value?: string | number
  }
}

/**
 * Reducer for changing product quantities
 */
export const reducer = (state: Product[], action: ReducerAction) => {
  switch (action.type) {
    case 'increase':
      return state.map((item: Product) => {
        if (action.payload.id === item.id) {
          const increasedValue = getIncreasedValue(item)
          return increasedValue
        } else {
          return item
        }
      })
    case 'decrease':
      return state.map((item: Product) => {
        if (action.payload.id === item.id) {
          const decreasedValue = getDecreasedValue(item)
          return decreasedValue
        } else {
          return item
        }
      })
    case 'remove':
      return state.map((item: Product) => {
        if (action.payload.id === item.id) {
          const updateItem = JSON.parse(JSON.stringify(item))
          return { ...updateItem, quantity: 0 }
        } else {
          return item
        }
      })
    case 'setQuantity':
      return state.map((item: Product) => {
        if (action.payload.id === item.id) {
          const payloadNumber = action.payload.value as number
          const updateItem = JSON.parse(JSON.stringify(item))

          return {
            ...updateItem,
            quantity: (updateItem.quantity = payloadNumber)
          }
        } else {
          return item
        }
      })
    default:
      return state
  }
}
