import { useDispatch } from 'react-redux'
import { CATALOG_ORDER } from 'GraphQL/queries'
import { useQuery } from '@apollo/client'
import { editSnackbar } from 'Features/snackbar'
import { setCurrentOrder } from 'Features/OrderSlice'

export const useOrder = (orderId: string) => {
  const dispatch = useDispatch()

  const { loading, refetch } = useQuery(CATALOG_ORDER, {
    variables: {
      order: orderId
    },
    onCompleted(result) {
      if (result.catalogOrder) {
        dispatch(
          setCurrentOrder({
            order: result.catalogOrder
          })
        )
      }
    },
    onError(error) {
      dispatch(
        editSnackbar({
          message: `Error. ${error}`,
          type: 'error'
        })
      )
    }
  })

  return { loading, refetch }
}
