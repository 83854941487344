import { useEffect, useReducer, useState } from 'react'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import { Layout, FlowNav, Add } from '@uniqore/module'
import AppBarMenu from 'Components/Common/AppBarMenu'
import {
  UqDivider,
  UqAvatar,
  UqList,
  UqListItem,
  UqListItemAvatar,
  UqListItemSecondaryAction,
  UqListItemText,
  UqTypography
} from '@uniqore/wrapper'
import { layoutProps } from 'Theme'
import { useNavigate, useParams } from 'react-router-dom'
import { URLS } from 'App'
import { round } from 'Components/Cart/Helpers/RoundPrice'
import useTypedSelector from 'Store'
import { Product, ProductWithQuantity } from 'Types/Common'
import ProductPage from 'Pages/EndUserPages/ProductPage'
import { addProduct, ProductInCart } from 'Features/CartSlice'
import { reducer } from 'Components/Cart/Helpers/ChangeQuantity'
import { useDispatch } from 'react-redux'
import { CATEGORIES } from 'App'
import { getDays, getNights } from 'Util/getDatesNightsBetween'
import BusinessIcon from '@mui/icons-material/Business'

interface SpacesFromProviderProps {
  spacesFromProvider: Product[]
  spaceId: string | undefined
}

const SpacesFromProvider = (props: SpacesFromProviderProps) => {
  const { spacesFromProvider, spaceId } = props

  const { lang } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Main'])

  const translations = useTypedSelector(state => state.translations)

  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [lang])

  const [activeProduct, setActiveProduct] = useState<ProductWithQuantity | null>(null)
  const [showProduct, setShowProduct] = useState<boolean>(false)
  const serviceProvider = useTypedSelector(state => state.airtableData.serviceProvider)

  const cartProducts = useTypedSelector(state => state.cartSlice.products)
  const spacesInCart = cartProducts.find(product => product.category === CATEGORIES.CoWorkingSpaces)

  const [reducerSpaces, dispatchReducer] = useReducer(
    reducer,
    spacesFromProvider.map(item => {
      let quantity = 0
      cartProducts.forEach(cartProduct => {
        if (item.id === cartProduct.productId) {
          quantity = cartProduct.productQuantity
        }
      })
      return {
        ...item,
        quantity
      }
    })
  )

  const spaces = reducerSpaces as ProductWithQuantity[]
  const activeProductQuantity = activeProduct
    ? spaces.find(i => i.id === activeProduct.id)?.quantity || 0
    : 0

  const getSpacePricePerDay = (
    productInCart: ProductInCart | undefined,
    product: ProductWithQuantity
  ) => {
    const days = productInCart ? getDays(productInCart) : 1
    const minDaysForPricePerWeek = 5
    const minDaysForPricePerMonth = 20
    if (days < minDaysForPricePerWeek && 'priceDay' in product.metadata.price) {
      return product.metadata.price.priceDay
    }

    if (
      days >= minDaysForPricePerWeek &&
      days < minDaysForPricePerMonth &&
      'priceWeek' in product.metadata.price
    ) {
      return product.metadata.price.priceWeek
    }

    if (days >= minDaysForPricePerMonth && 'priceMonth' in product.metadata.price) {
      return product.metadata.price.priceMonth
    }

    return 0
  }

  const getPrice = (productInCart: ProductInCart | undefined, product: ProductWithQuantity) => {
    const pricePerDay = getSpacePricePerDay(productInCart, product)
    const days = productInCart ? getDays(productInCart) : 1
    if (productInCart?.cartMeta.guests) {
      return product.metadata.type === 'Open office' || product.metadata.type === 'Separate office'
        ? pricePerDay * productInCart?.cartMeta.guests * days
        : pricePerDay * days
    } else return 0
  }

  const translatedProviderName =
    typeof serviceProvider?.fields.Name === 'string' && translations[serviceProvider.fields.Name]
      ? lang === 'en'
        ? translations[serviceProvider.fields.Name].key
        : lang === 'fi'
        ? translations[serviceProvider.fields.Name].fi
        : translations[serviceProvider.fields.Name].sv
      : serviceProvider?.fields.Name

  const translatedProviderDescription =
    typeof serviceProvider?.fields.Description === 'string' &&
    translations[serviceProvider.fields.Description]
      ? lang === 'en'
        ? translations[serviceProvider.fields.Description].key
        : lang === 'fi'
        ? translations[serviceProvider.fields.Description].fi
        : translations[serviceProvider.fields.Description].sv
      : serviceProvider?.fields.Description

  useEffect(() => {
    dispatch(
      addProduct(
        spaces.map(product => {
          const productInCart = cartProducts.find(
            cartProduct => cartProduct.productId == product.id
          )
          return {
            category: product.metadata.category,
            productId: product.id,
            productQuantity: product.quantity,
            cartMeta: {
              name: product.name,
              price: getPrice(productInCart, product),
              taxPercent: product.metadata.vat,
              taxAmount: product.metadata.vat * getPrice(productInCart, product),
              priceWithoutTax:
                getPrice(productInCart, product) -
                product.metadata.vat * getPrice(productInCart, product),
              option: product.name,
              productImage: '',
              catalogImage: '',
              sku: `${product.id}-${product.name.replaceAll('-', '%20')}-${getPrice(
                productInCart,
                product
              )}-day`,
              unit: 'day',
              startDate: productInCart?.cartMeta.startDate ?? '',
              endDate: productInCart?.cartMeta.endDate ?? '',
              guests: productInCart?.cartMeta.guests ?? 0,
              days: productInCart ? getDays(productInCart) : 0,
              nights: productInCart ? getNights(productInCart) : 0,
              serviceProviderId: serviceProvider.id,
              serviceProviderName: translatedProviderName,
              serviceProviderEmail:
                typeof serviceProvider.fields['Main contact'] === 'string'
                  ? serviceProvider.fields['Main contact']
                  : ''
            }
          }
        })
      )
    )
  }, [spaces])

  useEffect(() => {
    if (spaceId) {
      const product = spaces.find(space => space.id === spaceId)
      if (product) {
        setActiveProduct(product)
        setShowProduct(true)
      }
    }
  }, [spaceId])

  return showProduct && activeProduct ? (
    <ProductPage
      setShowProduct={setShowProduct}
      product={activeProduct}
      dispatchReducer={dispatchReducer}
      activeProductQuantity={activeProductQuantity}
    />
  ) : (
    <Layout
      {...layoutProps}
      showFooter
      top={<AppBarMenu title={t('Main:chooseSpace')}></AppBarMenu>}
      bottom={
        <FlowNav
          actionDirection="column"
          actionComponents={[
            {
              text: t('Main:next'),
              variant: 'contained',
              color: 'primary',
              onClick: () => navigate(`/${lang}/${URLS.CartPage}`),
              fullWidth: true,
              disabled: !spacesInCart
            }
          ]}
        />
      }
    >
      <>
        <UqList disablePadding={true}>
          <UqListItem>
            <UqListItemText
              primary={t('Main:coWorkingSpace')}
              secondary={translatedProviderName}
              primaryTypographyProps={{ color: 'text.primary' }}
              secondaryTypographyProps={{ color: 'text.secondary' }}
            />
          </UqListItem>
          <UqListItem marginTop={-24}>
            <UqListItemText
              primary={''}
              secondary={translatedProviderDescription}
              primaryTypographyProps={{ color: 'text.primary' }}
              secondaryTypographyProps={{ color: 'text.secondary' }}
            />
          </UqListItem>
        </UqList>
        <UqDivider variant="fullWidth" />
        <UqTypography variant="h6" marginTop={16}>
          {t('Main:spaces')}
        </UqTypography>
        <UqList disablePadding>
          {reducerSpaces.map(space => (
            <UqListItem
              key={space.id}
              onClick={() => {
                setActiveProduct(space)
                setShowProduct(true)
              }}
            >
              <UqListItemAvatar>
                <UqAvatar variant="square" sx={{ bgcolor: '#002444' }}>
                  <BusinessIcon />
                </UqAvatar>
              </UqListItemAvatar>
              <UqListItemText
                primary={
                  translations[space.name]
                    ? lang === 'en'
                      ? translations[space.name].key
                      : lang === 'fi'
                      ? translations[space.name].fi
                      : translations[space.name].sv
                    : space.name
                }
                secondary={
                  <>
                    <UqTypography
                      variant="body2"
                      color="text.secondary"
                      component="span" // needed for validate DOM-nesting
                      sx={{ display: 'block' }} // since component is 'span', set display to block
                    >
                      {round(space.metadata.price.priceDay) + ` / ${t('Main:day')}`}
                    </UqTypography>
                    <UqTypography
                      variant="body2"
                      color="text.secondary"
                      component="span"
                      sx={{ display: 'block' }}
                    >
                      {t('Main:capacity')}: {space.metadata.pax}
                    </UqTypography>
                  </>
                }
                primaryTypographyProps={{ color: 'text.primary' }}
                secondaryTypographyProps={{ color: 'text.secondary' }}
              />
              <UqListItemSecondaryAction>
                <Add
                  variant="multi"
                  edge="end"
                  color="secondary"
                  quantity={space.quantity}
                  unit={t('Main:pieces')}
                />
              </UqListItemSecondaryAction>
            </UqListItem>
          ))}
        </UqList>
      </>
    </Layout>
  )
}

export default SpacesFromProvider
