import { UqBox, UqChip } from '@uniqore/wrapper'
import { ProductWithQuantity } from 'Types/Common'

interface TagsProps {
  product: ProductWithQuantity
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  translations: any
  lang: string
}

export const ProductPageTags = (props: TagsProps) => {
  const { product, translations, lang } = props
  return (
    product.metadata.tags && (
      <UqBox marginTop={8}>
        {product.metadata.tags.map(tag => (
          <UqChip
            label={
              translations[tag]
                ? lang === 'en'
                  ? translations[tag].key
                  : lang === 'fi'
                  ? translations[tag].fi
                  : translations[tag].sv
                : tag
            }
            key={tag}
            marginRight={8}
            marginTop={8}
          />
        ))}
      </UqBox>
    )
  )
}
