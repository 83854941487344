import { round } from 'Components/Cart/Helpers/RoundPrice'
import { ProductWithQuantity } from 'Types/Common'
import { getDates, getDiff, isDateBetween } from 'Util/dateFunctions'

export const getSpacePricePerDay = (days: number, product: ProductWithQuantity) => {
  const minDaysForPricePerWeek = 5
  const minDaysForPricePerMonth = 20
  if (days < minDaysForPricePerWeek && 'priceDay' in product.metadata.price) {
    return product.metadata.price.priceDay
  }

  if (
    days >= minDaysForPricePerWeek &&
    days < minDaysForPricePerMonth &&
    'priceWeek' in product.metadata.price
  ) {
    return product.metadata.price.priceWeek
  }

  if (days >= minDaysForPricePerMonth && 'priceMonth' in product.metadata.price) {
    return product.metadata.price.priceMonth
  }

  return 0
}

export const getAccommodationPricePerNight = (
  nights: number,
  dates: Date[],
  product: ProductWithQuantity
) => {
  let totalPrice = 0
  dates.forEach(date => {
    const isHighSeason = checkSeason(date, product)
    const minNightsForPricePerWeek = 5
    const minNightsForPricePerMonth = 20
    if (isHighSeason) {
      if (nights < minNightsForPricePerWeek && 'priceNightHigh' in product.metadata.price) {
        totalPrice += product.metadata.price.priceNightHigh
      }

      if (
        nights >= minNightsForPricePerWeek &&
        nights < minNightsForPricePerMonth &&
        'priceWeekHigh' in product.metadata.price
      ) {
        totalPrice += product.metadata.price.priceWeekHigh
      }

      if (nights >= minNightsForPricePerMonth && 'priceMonthHigh' in product.metadata.price) {
        totalPrice += product.metadata.price.priceMonthHigh
      }
    } else {
      if (nights < minNightsForPricePerWeek && 'priceNightLow' in product.metadata.price) {
        totalPrice += product.metadata.price.priceNightLow
      }

      if (
        nights >= minNightsForPricePerWeek &&
        nights < minNightsForPricePerMonth &&
        'priceWeekLow' in product.metadata.price
      ) {
        totalPrice += product.metadata.price.priceWeekLow
      }

      if (nights >= minNightsForPricePerMonth && 'priceMonthLow' in product.metadata.price) {
        totalPrice += product.metadata.price.priceMonthLow
      }
    }
  })
  return totalPrice / nights
}

export const getSpacePricePerDayWithoutQuantity = (
  arrivingDate: Date,
  leavingDate: Date,
  product: ProductWithQuantity
) => {
  const diff = getDiff(arrivingDate, leavingDate)
  const days = Math.floor(diff / 86400000) + 1
  const pricePerDay = getSpacePricePerDay(days, product)
  return round(pricePerDay)
}

export const getAccommodationPricePerDayWithoutQuantity = (
  arrivingDate: Date,
  leavingDate: Date,
  product: ProductWithQuantity
) => {
  const diff = getDiff(arrivingDate, leavingDate)
  const nights = Math.floor(diff / 86400000)
  const dates = getDates(arrivingDate, leavingDate)
  const pricePerNight = getAccommodationPricePerNight(nights, dates, product)
  return round(pricePerNight)
}

export const truncateLongDesc = (
  str: string,
  num: number
): { string: string; isTruncated: boolean } => {
  if (str.length > num) {
    return {
      string: str.slice(0, num) + '...',
      isTruncated: true
    }
  } else {
    return {
      string: str,
      isTruncated: false
    }
  }
}

export const checkSeason = (date: Date, product: ProductWithQuantity) => {
  const highSeasonStart = product.metadata.highSeasonStart[0].split('.')
  const highSeasonStartDay = highSeasonStart[0]
  const highSeasonStartMonth = highSeasonStart[1]
  const highSeasonEnd = product.metadata.highSeasonEnd[0].split('.')
  const highSeasonEndDay = highSeasonEnd[0]
  const highSeasonEndMonth = highSeasonEnd[1]

  const highSeasonStartFullDate = new Date(
    `${highSeasonStartMonth}.${highSeasonStartDay}.${date.getFullYear()}`
  )
  const highSeasonEndFullDate = new Date(
    `${highSeasonEndMonth}.${highSeasonEndDay}.${date.getFullYear()}`
  )

  if (isDateBetween(date, highSeasonStartFullDate, highSeasonEndFullDate)) {
    return true
  }

  return false
}
