import React, { Dispatch, SetStateAction } from 'react'
import { UqTypography, UqAppBar, UqToolbar, UqIconButton } from '@uniqore/wrapper'
import { LanguageSwitcher } from 'Components/Common/LanguageSwitcher'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { URLS } from 'App'
import { useNavigate, useParams } from 'react-router-dom'
import { SxProps, Theme } from '@mui/material'

interface AppBarMenuProps {
  title: string
  sx?: SxProps<Theme>
  hasCloseIcon?: boolean
  color?: 'inherit' | 'primary' | 'secondary' | 'default' | 'transparent' | undefined
  isProductPage?: boolean
  setShowProduct?: Dispatch<SetStateAction<boolean>>
  showBottomBorder?: boolean
}

const AppBarMenu: React.FC<AppBarMenuProps> = ({
  title,
  hasCloseIcon = false,
  isProductPage = false,
  color = 'primary',
  showBottomBorder = false,
  sx,
  ...props
}) => {
  const { setShowProduct } = props
  const navigate = useNavigate()
  const { lang } = useParams()

  return (
    <UqAppBar
      position="relative"
      elevation={0}
      color={color}
      style={{
        borderBottom: showBottomBorder ? '1px solid rgba(0, 0, 0, 0.12)' : ''
      }}
      sx={sx}
    >
      <UqToolbar>
        <UqTypography variant="subtitle1" marginhorizontal={0} sx={{ flexGrow: 1 }}>
          {title}
        </UqTypography>
        {process.env.REACT_APP_SHOW_LANGUAGE_SWITCHER === 'true' && <LanguageSwitcher />}
        {hasCloseIcon && !isProductPage && (
          <UqIconButton
            size="large"
            aria-label="display more actions"
            edge="end"
            color="inherit"
            onClick={() => navigate(`/${lang}/${URLS.CartPage}`)}
          >
            <CloseRoundedIcon />
          </UqIconButton>
        )}
        {hasCloseIcon && isProductPage && setShowProduct !== undefined && (
          <UqIconButton
            size="large"
            aria-label="display more actions"
            edge="end"
            color="inherit"
            onClick={() => setShowProduct(false)}
          >
            <CloseRoundedIcon />
          </UqIconButton>
        )}
      </UqToolbar>
    </UqAppBar>
  )
}

export default AppBarMenu
