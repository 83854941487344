import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface OrderPerson {
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
}

interface ContactPerson {
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
}

interface CompanyDetails {
  name: string
  businessId: string
  address: string
  zipCode: string
  city: string
  country: string
}

type ContactDetailsSchema = {
  orderPerson: OrderPerson
  contactPerson: ContactPerson
  companyDetails: CompanyDetails
}

const initialState: ContactDetailsSchema = {
  orderPerson: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: ''
  },
  contactPerson: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: ''
  },
  companyDetails: {
    name: '',
    businessId: '',
    address: '',
    zipCode: '',
    city: '',
    country: ''
  }
}

const contactDetailsSlice = createSlice({
  name: 'contactDetails',
  initialState,
  reducers: {
    updateContactDetails: (
      state,
      action: PayloadAction<{
        key: keyof ContactDetailsSchema
        value: OrderPerson | ContactPerson | CompanyDetails
      }>
    ) => {
      const { key, value } = action.payload
      switch (key) {
        case 'orderPerson':
          state[key] = value as OrderPerson
          break
        case 'contactPerson':
          state[key] = value as ContactPerson
          break
        case 'companyDetails':
          state[key] = value as CompanyDetails
          break
        default:
          break
      }
    },
    clearContactDetails: state => {
      state = initialState
      return state
    }
  }
})

export const { updateContactDetails } = contactDetailsSlice.actions
export default contactDetailsSlice.reducer
