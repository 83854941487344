import { FC, useEffect } from 'react'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import { Layout, FlowNav, Image } from '@uniqore/module'
import { UqTypography } from '@uniqore/wrapper'
import { layoutProps } from 'Theme'
import { useLocation } from 'react-router-dom'
import AppBarMenu from 'Components/Common/AppBarMenu'
import { getMainWebsiteUrl } from 'Util'

const WelcomePage: FC = () => {
  const location = useLocation()
  const lang = location.pathname.split('/')[1]
  const { t } = useTranslation(['Main'])
  const returnLink = getMainWebsiteUrl(lang)

  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [lang])
  return (
    <Layout
      {...layoutProps}
      showFooter
      style={{ position: 'relative' }}
      top={<AppBarMenu title={''} color="inherit" showBottomBorder={true}></AppBarMenu>}
      bottom={
        <FlowNav
          actionDirection="column"
          actionComponents={[
            {
              text: 'Continue',
              variant: 'contained',
              color: 'secondary',
              onClick: () => returnLink && window.location.replace(returnLink),
              fullWidth: true
            }
          ]}
        />
      }
    >
      <Image
        src="https://www.apelago.fi/apelago-logo-box.svg"
        width={80}
        center
        marginBottom={60}
        marginTop={100}
      />
      <UqTypography variant="h6" color="text.primary" align="center">
        {t('Main:errorPageTitle')}
      </UqTypography>
      <UqTypography variant="body1" color="text.secondary" align="center">
        {t('Main:errorPageDescription')}
      </UqTypography>
    </Layout>
  )
}

export default WelcomePage
