import { useDispatch } from 'react-redux'
import { CATALOG_CATEGORIES } from 'GraphQL/queries'
import { useQuery } from '@apollo/client'
import { setCategories } from 'Features/AirtableSlice'
import { editSnackbar } from 'Features/snackbar'

export const useCategories = () => {
  const dispatch = useDispatch()

  const { loading } = useQuery(CATALOG_CATEGORIES, {
    variables: {
      catalog: 'main',
      parent: null
    },
    onCompleted(result) {
      if (result.catalogCollections) {
        dispatch(setCategories({ airtableCategories: result.catalogCollections }))
      }
    },
    onError(error) {
      dispatch(
        editSnackbar({
          message: `Error. ${error}`,
          type: 'error'
        })
      )
    }
  })

  return { loading }
}
