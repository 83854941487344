import i18n from 'i18next'
import { UqLink, UqTypography } from '@uniqore/wrapper'
import { useNavigate } from 'react-router-dom'
import { useBasePath } from 'Util/useBasePath'

export const LanguageSwitcher = () => {
  const navigate = useNavigate()
  const basePath = useBasePath()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const switchLanguage = (e: any) => {
    const currentLang = e.target.name
    navigate(`/${currentLang}/${basePath}`)
  }

  return (
    <UqTypography variant="caption" sx={{ fontSize: '16px' }}>
      <UqLink
        name="en"
        onClick={e => switchLanguage(e)}
        sx={{
          cursor: 'pointer',
          color: 'inherit',
          textDecoration: 'none',
          fontWeight: i18n.language === 'en' ? 'bolder' : 'normal'
        }}
      >
        EN{` `}
      </UqLink>
      /
      <UqLink
        name="fi"
        onClick={e => switchLanguage(e)}
        sx={{
          cursor: 'pointer',
          color: 'inherit',
          textDecoration: 'none',
          fontWeight: i18n.language === 'fi' ? 'bolder' : 'normal'
        }}
      >
        {` `}FI{` `}
      </UqLink>
      /
      <UqLink
        name="sv"
        onClick={e => switchLanguage(e)}
        sx={{
          cursor: 'pointer',
          color: 'inherit',
          textDecoration: 'none',
          fontWeight: i18n.language === 'sv' ? 'bolder' : 'normal'
        }}
      >
        {` `}SV
      </UqLink>
    </UqTypography>
  )
}
