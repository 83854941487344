import { useEffect } from 'react'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import useTypedSelector from 'Store'

const InitCurrentStatus = (status: string) => {
  const { lang: paramsLang } = useParams()
  const order = useTypedSelector(state => state.order.currentOrder)
  const isPartlyConfirmedOrder =
    order.state === 'ACCEPTED' && order.items.some(item => item.metadata.status === 'Declined')

  const { t } = useTranslation(['Main', 'Order'])

  useEffect(() => {
    i18n.changeLanguage(paramsLang)
  }, [paramsLang])

  const statusIndicatorValues = [
    {
      title: t('Order:waitingForConfirmation'),
      description: t('Order:waitingForConfirmationDescription'),
      buttonText: ''
    },
    {
      title: t('Order:waitingForPayment'),
      description: t('Order:waitingForPaymentDecription'),
      buttonText: t('Main:payNow')
    },
    {
      title: t('Order:reservationCancelled'),
      description: t('Order:reservationCancelledDescription'),
      buttonText: t('Order:makeNewOrder')
    },
    {
      title: t('Order:changesInYourOrder'),
      description: t('Order:changesInYourOrderDescription'),
      buttonText: t('Main:payNow')
    },
    {
      title: t('Order:createdOrderTitle'),
      description: t('Order:createdOrderDescription'),
      buttonText: ''
    },
    {
      title: t('Order:reservationCancelled'),
      description: t('Order:reservationCancelledByCustomerDescription'),
      buttonText: t('Order:makeNewOrder')
    },
    {
      title: t('Order:expiredOrderTitle'),
      description: t('Order:expiredOrderDescription'),
      buttonText: t('Order:makeNewOrder')
    }
  ]
  switch (status) {
    case 'AWAITING_REVIEW':
      return statusIndicatorValues[0]
    case 'ACCEPTED':
      if (isPartlyConfirmedOrder) {
        return statusIndicatorValues[3]
      }
      return statusIndicatorValues[1]
    case 'REJECTED':
      return statusIndicatorValues[2]
    case 'CANCELLED':
      return statusIndicatorValues[5]
    case 'AWAITING_PAYMENT':
      if (isPartlyConfirmedOrder) {
        return statusIndicatorValues[3]
      }
      return statusIndicatorValues[1]
    case 'PAID':
      return statusIndicatorValues[4]
    case 'EXPIRED':
      return statusIndicatorValues[6]
    default:
      return statusIndicatorValues[0]
  }
}

export default InitCurrentStatus
