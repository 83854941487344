import { UqTypography } from '@uniqore/wrapper'
import { CATEGORIES } from 'App'
import { ProductWithQuantity } from 'Types/Common'
import {
  getSpacePricePerDayWithoutQuantity,
  getAccommodationPricePerDayWithoutQuantity
} from 'Util/productPageCalculations'

interface PricePerUnitSectionProps {
  product: ProductWithQuantity
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  translations: any
  arrivingDate: Date
  leavingDate: Date
  lang: string
}

export const PricePerUnitSection = (props: PricePerUnitSectionProps) => {
  const { product, translations, arrivingDate, leavingDate, lang } = props
  return product.metadata.category === CATEGORIES.CoWorkingSpaces ? (
    <UqTypography variant="subtitle2" color="text.primary">
      {getSpacePricePerDayWithoutQuantity(arrivingDate, leavingDate, product)} /{' '}
      {translations[product.metadata.unit]
        ? lang === 'en'
          ? translations[product.metadata.unit].key
          : lang === 'fi'
          ? translations[product.metadata.unit].fi
          : translations[product.metadata.unit].sv
        : product.metadata.unit}
    </UqTypography>
  ) : product.metadata.category === CATEGORIES.Accommodation ? (
    <UqTypography variant="subtitle2" color="text.primary">
      {getAccommodationPricePerDayWithoutQuantity(arrivingDate, leavingDate, product)} /{' '}
      {translations[product.metadata.unit]
        ? lang === 'en'
          ? translations[product.metadata.unit].key
          : lang === 'fi'
          ? translations[product.metadata.unit].fi
          : translations[product.metadata.unit].sv
        : product.metadata.unit}
    </UqTypography>
  ) : (
    <UqTypography variant="subtitle2" color="text.primary">
      {product.variants[0].metadata.numericPrice} € /
      {translations[product.variants[0].metadata.unit]
        ? lang === 'en'
          ? translations[product.variants[0].metadata.unit].key
          : lang === 'fi'
          ? translations[product.variants[0].metadata.unit].fi
          : translations[product.variants[0].metadata.unit].sv
        : product.variants[0].metadata.unit}
    </UqTypography>
  )
}
