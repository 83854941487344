import { UqList, UqListItem, UqListItemText, UqTypography } from '@uniqore/wrapper'

interface HeadingListItemProps {
  title: string
  subtitle: string
}

const HeadingListItem = (props: HeadingListItemProps) => {
  const { title, subtitle } = props
  return (
    <UqList disablePadding={true}>
      <UqListItem style={{ padding: 0, margin: 0 }}>
        <UqListItemText
          primary={title}
          secondary={
            <>
              <UqTypography
                variant="body2"
                color="text.secondary"
                component="span" // needed for validate DOM-nesting
                sx={{ display: 'block' }} // since component is 'span', set display to block
              >
                {subtitle}
              </UqTypography>
            </>
          }
          primaryTypographyProps={{ color: 'text.primary' }}
          secondaryTypographyProps={{ color: 'text.secondary' }}
        />
      </UqListItem>
    </UqList>
  )
}

export default HeadingListItem
