import { ProductInCart } from 'Features/CartSlice'

export const getFormmattedDateFromDayMonthYear = (date: string | null) => {
  if (date) {
    const dateParts = date?.split('.')
    const day = dateParts[0]
    const month = dateParts[1]
    const year = dateParts[2]
    const dateString = `${year}-${month}-${day}`
    return dateString
  } else return new Date()
}

export const getFormmattedDateFromMonthDayYear = (date: string | null) => {
  if (date) {
    const dateParts = date?.split('.')
    const day = dateParts[1]
    const month = dateParts[0]
    const year = dateParts[2]
    const dateString = `${year}-${month}-${day}`
    return dateString
  } else return new Date()
}

export const getDiff = (productInCart: ProductInCart) => {
  const startDateParts = productInCart.cartMeta?.startDate?.split('.')
  const endDateParts = productInCart.cartMeta?.endDate?.split('.')
  if (startDateParts && endDateParts) {
    const arrivingDate = new Date(`${startDateParts[2]}-${startDateParts[1]}-${startDateParts[0]}`)
    const leavingDate = new Date(`${endDateParts[2]}-${endDateParts[1]}-${endDateParts[0]}`)
    const startTimestamp = arrivingDate.getTime()
    const endTimestamp = leavingDate.getTime()
    const diff = endTimestamp - startTimestamp
    return diff
  }
  return 0
}

export const getDays = (productInCart: ProductInCart) => {
  const diff = getDiff(productInCart)
  const days = Math.floor(diff / 86400000) + 1
  return days
}

export const getNights = (productInCart: ProductInCart) => {
  const diff = getDiff(productInCart)

  const nights = Math.floor(diff / 86400000)
  return nights
}
