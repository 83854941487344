import { useDispatch } from 'react-redux'
import { CATALOG_PRODUCTS } from 'GraphQL/queries'
import { initProducts } from 'Features/AirtableSlice'
import { useQuery } from '@apollo/client'
import { editSnackbar } from 'Features/snackbar'
import { Product } from 'Types/Common'

export const useCatalogProducts = (categories: string[], providerId: string) => {
  const dispatch = useDispatch()

  const { loading } = useQuery(CATALOG_PRODUCTS, {
    variables: {
      catalog: 'main',
      collections: categories
    },
    onCompleted(result) {
      if (result.catalogProducts && providerId) {
        const products = result.catalogProducts
          .map((product: Product) =>
            product.metadata.serviceProviders &&
            product.metadata.serviceProviders.some(provider => provider === providerId)
              ? product
              : undefined
          )
          .filter((product: Product) => product)

        const relatedProducts = result.catalogProducts
          .map((product: Product) =>
            product.metadata.relatedServiceProvider &&
            product.metadata.relatedServiceProvider.some(provider => provider === providerId)
              ? product
              : undefined
          )
          .filter((product: Product) => product)

        dispatch(initProducts({ airtableProducts: [...products, ...relatedProducts] }))
      }
    },
    onError(error) {
      dispatch(
        editSnackbar({
          message: `Error. ${error}`,
          type: 'error'
        })
      )
    }
  })

  return { loading }
}
