import { FC, useEffect } from 'react'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import { Layout, Image } from '@uniqore/module'
import { UqTypography, UqBox, UqDivider } from '@uniqore/wrapper'
import { layoutProps } from 'Theme'
import HeadingListItem from 'Components/Order/HeadingListItem'
import ServiceStatusCard from 'Components/Order/ServiceStatusCard'
import useTypedSelector from 'Store'
import { useParams } from 'react-router-dom'
import { CATEGORIES } from 'App'
import { round } from 'Components/Cart/Helpers/RoundPrice'
import { ProductInCart } from 'Features/CartSlice'

const FinalSplash: FC = () => {
  const serviceProvider = useTypedSelector(state => state.airtableData.serviceProvider)
  const { lang } = useParams()
  const orderId = useTypedSelector(state => state.order.preOrder.id)
  const orderItems = useTypedSelector(state => state.order.preOrder.items)
  const spacesInCart = orderItems.filter(product => product.category === CATEGORIES.CoWorkingSpaces)
  const accommodationInCart = orderItems.filter(
    product => product.category === CATEGORIES.Accommodation
  )
  const foodAndBeveragesInCart = orderItems.filter(
    product => product.category === CATEGORIES.FoodAndBeverages
  )
  const activitiesInCart = orderItems.filter(product => product.category === CATEGORIES.Activities)

  const { t } = useTranslation(['Main', 'Order'])
  const translations = useTypedSelector(state => state.translations)

  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [lang])

  const getPrice = (item: ProductInCart) => {
    if (item.category === CATEGORIES.CoWorkingSpaces && item.cartMeta.days) {
      return round(item.cartMeta.price * item.cartMeta.days)
    }
    if (item.category === CATEGORIES.Accommodation) {
      return round(item.cartMeta.price * item.productQuantity)
    }
    return round(item.cartMeta.price * item.productQuantity)
  }

  const getTranslatedItemName = (item: ProductInCart) => {
    return translations[item.cartMeta.name]
      ? lang === 'en'
        ? translations[item.cartMeta.name].key
        : lang === 'fi'
        ? translations[item.cartMeta.name].fi
        : translations[item.cartMeta.name].sv
      : item.cartMeta.name
  }
  return (
    <Layout {...layoutProps} showFooter scrollableContent={true}>
      <Image
        src="https://www.apelago.fi/apelago-logo-box.svg"
        width={60}
        center
        marginVertical={24}
      />
      <UqBox style={{ display: 'flex', justifyContent: 'center' }}>
        <UqTypography variant="overline">
          {t('Order:order')} {orderId}
        </UqTypography>
      </UqBox>
      <UqTypography variant="h6" color="text.primary" align="center" marginBottom={8}>
        {t('Order:preOrderCreated')}
      </UqTypography>
      <UqTypography variant="body2" color="text.secondary" align="center">
        {t('Order:waitingForConfirmationDescription')}
      </UqTypography>
      <UqDivider marginVertical={24} />
      {spacesInCart.length > 0 && (
        <>
          <HeadingListItem
            title={t('Main:coWorkingSpace')}
            subtitle={
              typeof serviceProvider.fields.Name === 'string' ? serviceProvider.fields.Name : ''
            }
          />
          {spacesInCart.map(space => {
            return (
              <ServiceStatusCard
                key={space.productId}
                title={getTranslatedItemName(space)}
                subtitles={[
                  `${space.cartMeta.startDate} - ${space.cartMeta.endDate}`,
                  `${space.cartMeta.guests} ${t('Main:guests')}`,
                  `${getPrice(space)}`
                ]}
                status={t('Main:waitingStatus')}
              />
            )
          })}
        </>
      )}
      {accommodationInCart.length > 0 && (
        <>
          <HeadingListItem
            title={t('Main:accommodation')}
            subtitle={
              typeof serviceProvider.fields.Name === 'string' ? serviceProvider.fields.Name : ''
            }
          />
          {accommodationInCart.map(accommodation => {
            return (
              <ServiceStatusCard
                key={accommodation.productId}
                title={`${accommodation.productQuantity} x ${getTranslatedItemName(accommodation)}`}
                subtitles={[
                  `${accommodation.cartMeta.startDate} - ${accommodation.cartMeta.endDate}`,
                  `${accommodation.cartMeta.guests} ${t('Main:guests')}`,
                  `${getPrice(accommodation)}`
                ]}
                status={t('Main:waitingStatus')}
              />
            )
          })}
        </>
      )}
      {foodAndBeveragesInCart.length > 0 && (
        <>
          <HeadingListItem
            title={t('Main:foodAndBeverages')}
            subtitle={
              typeof serviceProvider.fields.Name === 'string' ? serviceProvider.fields.Name : ''
            }
          />
          {foodAndBeveragesInCart.map(item => {
            return (
              <ServiceStatusCard
                key={item.productId}
                title={`${item.productQuantity} x ${getTranslatedItemName(item)}`}
                subtitles={[`${getPrice(item)}`]}
                status={t('Main:waitingStatus')}
              />
            )
          })}
        </>
      )}
      {activitiesInCart.length > 0 && (
        <>
          <HeadingListItem
            title={t('Main:activites')}
            subtitle={
              typeof serviceProvider.fields.Name === 'string' ? serviceProvider.fields.Name : ''
            }
          />
          {activitiesInCart.map(activity => {
            return (
              <ServiceStatusCard
                key={activity.productId}
                title={getTranslatedItemName(activity)}
                status=""
                subtitles={[]}
              />
            )
          })}
        </>
      )}
    </Layout>
  )
}

export default FinalSplash
