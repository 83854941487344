import { gql } from '@apollo/client'

export const CATALOG_PRODUCTS = gql`
  query catalogProducts($catalog: ID!, $collections: [ID!]) {
    catalogProducts(catalog: $catalog, collections: $collections) {
      id
      name
      description
      image {
        url
      }
      limited
      metadata
      variants {
        sku
        name
        price
        available
        limited
        metadata
        images {
          url
        }
      }
    }
  }
`

export const CATALOG_CATEGORIES = gql`
  query catalogCollections($catalog: ID!, $parent: ID) {
    catalogCollections(catalog: $catalog, parent: $parent) {
      id
      name
      description
    }
  }
`

export const CATALOG_ORDER = gql`
  query catalogOrder($order: ID!) {
    catalogOrder(order: $order) {
      id
      catalog
      state
      items {
        sku
        title
        totalPrice
        unitPrice
        quantity
        tax
        accepted
        metadata
      }
      total
      paymentUrl
      metadata
      comments {
        byCustomer
        user {
          id
          email
          firstName
          lastName
          organization {
            id
            name
            slug
          }
          metadata
          ... on OrgUser {
            title
          }
        }
        timestamp
        comment
      }
    }
  }
`

export const FETCH_DATAVIEW = gql`
  query fetchDataview($id: ID!) {
    dataview(id: $id) {
      ... on ObjectDatasourceResult {
        data
      }
    }
  }
`

export const GET_ORG_USER = gql`
  query thisUser {
    thisUser {
      id
      email
      metadata
      ... on OrgUser {
        title
      }
    }
  }
`
