import { UqBox, UqTypography } from '@uniqore/wrapper'

type CommentProps = {
  comment: string
  provider?: string
}

const OrderComment = (props: CommentProps) => {
  const { comment, provider } = props
  return (
    <UqBox
      backgroundColor="#fdfbf5"
      style={{ border: '1px solid #80808040', display: 'flex', flexDirection: 'column' }}
    >
      <UqTypography
        variant="body2"
        color="text.secondary"
        align="center"
        style={{ padding: provider ? '16px 0px 0px 0px' : '16px 0' }}
      >
        &quot;{comment}&quot;
      </UqTypography>
      {provider && (
        <UqTypography
          variant="body2"
          color="text.secondary"
          align="center"
          style={{ padding: '0px 0px 16px 0px' }}
        >
          {`${provider}`}
        </UqTypography>
      )}
    </UqBox>
  )
}

export default OrderComment
