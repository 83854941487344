import { combineReducers } from '@reduxjs/toolkit'
import snackbar from 'Features/snackbar'
import contactDetails from 'Features/ContactDetailsSlice'
import order from 'Features/OrderSlice'
import airtableData from 'Features/AirtableSlice'
import cartSlice from 'Features/CartSlice'
import translations from 'Features/TranslationsSlice'

const rootReducer = combineReducers({
  contactDetails,
  snackbar,
  order,
  airtableData,
  cartSlice,
  translations
})

export type RootState = ReturnType<typeof rootReducer>
export default rootReducer
