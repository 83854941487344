import { FC, useEffect } from 'react'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import { Layout, FlowNav } from '@uniqore/module'
import {
  UqTypography,
  UqFormGroup,
  UqDivider,
  UqFormControlLabel,
  UqCheckbox,
  UqList,
  UqListItem,
  UqListItemText,
  UqListItemSecondaryAction,
  UqButton,
  UqCircularProgress,
  UqLink,
  UqBackdrop
} from '@uniqore/wrapper'
import AppBarMenu from 'Components/Common/AppBarMenu'
import SummaryCart from 'Components/Cart/SummaryCart'
import { useNavigate, useParams } from 'react-router-dom'
import { layoutProps } from 'Theme'
import { CATEGORIES, URLS } from 'App'
import { CREATE_CATALOG_ORDER } from 'GraphQL/mutations'
import ModeEditOutlineRoundedIcon from '@mui/icons-material/ModeEditOutlineRounded'
import { useMutation } from '@apollo/client'
import useTypedSelector from 'Store'
import { confirmRule, setPreOrder } from 'Features/OrderSlice'
import { useDispatch } from 'react-redux'
import { useCart, getDateFromString } from 'Util'
import { editSnackbar } from 'Features/snackbar'
import { cleanCart } from 'Features/CartSlice'
import { checkUniqureProviders } from 'Util'

const SummaryPage: FC = () => {
  const navigate = useNavigate()
  const { lang } = useParams()
  const dispatch = useDispatch()

  const { t } = useTranslation(['Main', 'Cart', 'ContactDetails', 'Order'])

  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [lang])

  const contactDetails = useTypedSelector(state => state.contactDetails)
  const isConfirmedRule = useTypedSelector(state => state.order.isConfirmedRule)
  const cartProducts = useTypedSelector(state => state.cartSlice.products)

  const serviceProviderEmails = cartProducts.map(product => product.cartMeta.serviceProviderEmail)
  const uniqueProviderEmails = serviceProviderEmails.filter(checkUniqureProviders)

  const cartProductsToOrder = cartProducts.map(product => {
    if (product) {
      return {
        sku: product.cartMeta.sku,
        quantity: product.productQuantity,
        metadata: {
          productId: product.productId,
          productName: product.cartMeta.name,
          vatPercent: product.cartMeta.taxPercent,
          numericPrice: product.cartMeta.price,
          category: product.category,
          pax: product.cartMeta.guests,
          unit: product.cartMeta.unit,
          startDate: product.cartMeta.startDate
            ? getDateFromString(product.cartMeta.startDate)
            : null,
          startDateString: product.cartMeta.startDate,
          endDate: product.cartMeta.endDate ? getDateFromString(product.cartMeta.endDate) : null,
          endDateString: product.cartMeta.endDate,
          status: 'Waiting',
          serviceProvider: product.cartMeta.serviceProviderName,
          days: product.cartMeta.days ?? 0,
          nights: product.cartMeta.nights ?? 0,
          serviceProviderId: product.cartMeta.serviceProviderId,
          serviceProviderEmail: product.cartMeta.serviceProviderEmail
        }
      }
    }
  })

  const { cartTotalAmount, cartTaxesAmount } = useCart(cartProducts)

  const [createOrder, { loading: orderLoading }] = useMutation(CREATE_CATALOG_ORDER, {
    onCompleted(result) {
      if (result.createCatalogOrder) {
        dispatch(
          setPreOrder({
            preOrder: {
              id: result.createCatalogOrder.id,
              items: cartProducts
            }
          })
        )
        dispatch(cleanCart())
        navigate(`/${lang}/${URLS.FinalSplashPage}`)
      } else {
        dispatch(
          editSnackbar({
            message: t('common:error'),
            type: 'error'
          })
        )
      }
    },
    onError(error) {
      dispatch(
        editSnackbar({
          message: `Error. ${error}`,
          type: 'error'
        })
      )
    }
  })

  const createPreOrder = () => {
    createOrder({
      variables: {
        checkout: 'main',
        checkoutNow: true,
        items: cartProductsToOrder.filter(
          product => product?.metadata.category !== CATEGORIES.Activities
        ),
        metadata: {
          companyName: contactDetails.companyDetails.name,
          businessId: contactDetails.companyDetails.businessId,
          companyAddress: contactDetails.companyDetails.address,
          companyZip: contactDetails.companyDetails.zipCode,
          companyCity: contactDetails.companyDetails.city,
          companyCountry: contactDetails.companyDetails.country,
          orderFirstName: contactDetails.orderPerson.firstName,
          orderLastName: contactDetails.orderPerson.lastName,
          orderEmail: contactDetails.orderPerson.email,
          orderPhone: contactDetails.orderPerson.phoneNumber,
          contactFirstName:
            contactDetails.contactPerson.firstName !== ''
              ? contactDetails.contactPerson.firstName
              : contactDetails.orderPerson.firstName,
          contactLastName:
            contactDetails.contactPerson.lastName !== ''
              ? contactDetails.contactPerson.lastName
              : contactDetails.orderPerson.lastName,
          contactEmail:
            contactDetails.contactPerson.email !== ''
              ? contactDetails.contactPerson.email
              : contactDetails.orderPerson.email,
          contactPhone:
            contactDetails.contactPerson.phoneNumber !== ''
              ? contactDetails.contactPerson.phoneNumber
              : contactDetails.orderPerson.phoneNumber,
          isSameContactPerson: contactDetails.contactPerson.firstName ? false : true,
          taxAmount: cartTaxesAmount(),
          language: lang,
          serviceProviderComment: '',
          uniqueProviderEmails: uniqueProviderEmails,
          activities: cartProductsToOrder.filter(
            product => product?.metadata.category === CATEGORIES.Activities
          )
        }
      }
    })
  }

  const getTermsLink = () => {
    switch (lang) {
      case 'fi':
        return 'https://www.apelago.fi/fi/ehdot'
      case 'sv':
        return 'https://www.apelago.fi/sv/villkor'
      case 'en':
        return 'https://www.apelago.fi/en/terms'
      default:
        return 'https://www.apelago.fi/fi/ehdot'
    }
  }

  const termsLink = getTermsLink()

  return (
    <Layout
      {...layoutProps}
      top={<AppBarMenu title={t('Main:summary')} />}
      scrollableContent={true}
      showFooter
      bottom={
        <FlowNav
          actionDirection="column"
          actionComponents={[
            {
              text: `${t('Order:createPreOrder')} (${cartTotalAmount()})`,
              variant: 'contained',
              color: 'primary',
              onClick: () => createPreOrder(),
              fullWidth: true,
              disabled: !isConfirmedRule
            },
            {
              text: t('Main:back'),
              variant: 'text',
              color: 'primary',
              onClick: () => navigate(`/${lang}/${URLS.ContactDetailsPage}`),
              fullWidth: true
            }
          ]}
        />
      }
    >
      <UqBackdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={orderLoading}
      >
        <UqCircularProgress color="inherit" />
      </UqBackdrop>
      <UqTypography variant="h6" color="text.primary" align="left">
        {t('Main:summary')}
      </UqTypography>
      <UqTypography variant="body2" color="text.secondary">
        {t('Cart:paymentInfo')}
      </UqTypography>

      <UqList>
        <UqListItem>
          <UqListItemText
            primary={t('Cart:yourCart')}
            primaryTypographyProps={{ color: 'text.primary' }}
          />
          <UqListItemSecondaryAction>
            <UqButton
              color="secondary"
              variant="text"
              onClick={() => navigate(`/${lang}/${URLS.CartPage}`)}
              style={{ minWidth: 24, marginRight: -20 }}
            >
              <ModeEditOutlineRoundedIcon />
            </UqButton>
          </UqListItemSecondaryAction>
        </UqListItem>
      </UqList>

      <SummaryCart />

      <UqList>
        <UqListItem>
          <UqListItemText
            primary={t('Main:companyDetails')}
            primaryTypographyProps={{ color: 'text.primary' }}
            secondary={
              <>
                <UqTypography
                  variant="body2"
                  color="text.secondary"
                  component="span" // needed for validate DOM-nesting
                  sx={{ display: 'block' }} // since component is 'span', set display to block
                >
                  {`${contactDetails.companyDetails.name} (${contactDetails.companyDetails.businessId})`}
                </UqTypography>
                <UqTypography
                  variant="body2"
                  color="text.secondary"
                  component="span"
                  sx={{ display: 'block' }}
                >
                  {contactDetails.companyDetails.address}
                </UqTypography>
                <UqTypography
                  variant="body2"
                  color="text.secondary"
                  component="span"
                  sx={{ display: 'block' }}
                >
                  {`${contactDetails.companyDetails.zipCode}, ${contactDetails.companyDetails.city}, ${contactDetails.companyDetails.country}`}
                </UqTypography>
              </>
            }
          />
          <UqListItemSecondaryAction>
            <UqButton
              color="secondary"
              variant="text"
              onClick={() => navigate(`/${lang}/${URLS.CompanyDetailsPage}`)}
              style={{ minWidth: 24, marginRight: -20 }}
            >
              <ModeEditOutlineRoundedIcon />
            </UqButton>
          </UqListItemSecondaryAction>
        </UqListItem>

        <UqDivider variant="fullWidth" />

        <UqListItem>
          <UqListItemText
            primary={t('Main:customerDetails')}
            primaryTypographyProps={{ color: 'text.primary' }}
            secondary={
              <>
                <UqTypography
                  variant="body2"
                  color="text.secondary"
                  component="span"
                  sx={{ display: 'block' }}
                >
                  {contactDetails.orderPerson.firstName} {contactDetails.orderPerson.lastName}
                </UqTypography>
                <UqTypography
                  variant="body2"
                  color="text.secondary"
                  component="span"
                  sx={{ display: 'block' }}
                >
                  {contactDetails.orderPerson.email}
                </UqTypography>
                <UqTypography
                  variant="body2"
                  color="text.secondary"
                  component="span"
                  sx={{ display: 'block' }}
                >
                  {contactDetails.orderPerson.phoneNumber}
                </UqTypography>
              </>
            }
          />
          <UqListItemSecondaryAction>
            <UqButton
              color="secondary"
              variant="text"
              onClick={() => navigate(`/${lang}/${URLS.ContactDetailsPage}`)}
              style={{ minWidth: 24, marginRight: -20 }}
            >
              <ModeEditOutlineRoundedIcon />
            </UqButton>
          </UqListItemSecondaryAction>
        </UqListItem>

        {contactDetails.contactPerson.email && (
          <>
            <UqDivider variant="fullWidth" />

            <UqListItem>
              <UqListItemText
                primary={`${t('ContactDetails:contactPerson')}`}
                primaryTypographyProps={{ color: 'text.primary' }}
                secondary={
                  <>
                    <UqTypography
                      variant="body2"
                      color="text.secondary"
                      component="span"
                      sx={{ display: 'block' }}
                    >
                      {contactDetails.contactPerson.firstName}{' '}
                      {contactDetails.contactPerson.lastName}
                    </UqTypography>
                    <UqTypography
                      variant="body2"
                      color="text.secondary"
                      component="span"
                      sx={{ display: 'block' }}
                    >
                      {contactDetails.contactPerson.email}
                    </UqTypography>
                    <UqTypography
                      variant="body2"
                      color="text.secondary"
                      component="span"
                      sx={{ display: 'block' }}
                    >
                      {contactDetails.contactPerson.phoneNumber}
                    </UqTypography>
                  </>
                }
              />
              <UqListItemSecondaryAction>
                <UqButton
                  color="secondary"
                  variant="text"
                  onClick={() => navigate(`/${lang}/${URLS.ContactDetailsPage}`)}
                  style={{ minWidth: 24, marginRight: -20 }}
                >
                  <ModeEditOutlineRoundedIcon />
                </UqButton>
              </UqListItemSecondaryAction>
            </UqListItem>
          </>
        )}
      </UqList>

      <UqDivider variant="fullWidth" />

      <UqListItem>
        <UqListItemText
          primary={`${t('Main:paymentMethod')}: Stripe`}
          primaryTypographyProps={{ color: 'text.primary' }}
          secondary={
            <>
              <UqTypography
                variant="body2"
                color="text.secondary"
                component="span"
                sx={{ display: 'block' }}
              >
                {t('Cart:stripeInfo')}
              </UqTypography>
            </>
          }
        />
      </UqListItem>

      {/* Agreement checkbox */}
      <UqFormGroup marginLeft={16}>
        <UqFormControlLabel
          control={
            <UqCheckbox
              size="medium"
              color="primary"
              checked={isConfirmedRule}
              onChange={e => dispatch(confirmRule({ isConfirmedRule: e.target.checked }))}
            />
          }
          label={
            <UqTypography>
              <UqLink target="blank" href={termsLink}>
                {t('Cart:acceptTerms')}
              </UqLink>
              .
            </UqTypography>
          }
        />
      </UqFormGroup>
    </Layout>
  )
}

export default SummaryPage
