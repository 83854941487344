import { useEffect } from 'react'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import { Add } from '@uniqore/module'
import {
  UqBox,
  UqButton,
  UqList,
  UqListItemText,
  UqListItem,
  UqDivider,
  UqListItemSecondaryAction,
  UqTypography
} from '@uniqore/wrapper'
import AddIcon from '@mui/icons-material/Add'
import { Category } from 'Types/Common'
import { useNavigate, useParams } from 'react-router-dom'
import useTypedSelector from 'Store'
import { CATEGORIES } from 'App'
import { ProductInCart } from 'Features/CartSlice'
import { round } from './Helpers/RoundPrice'

type CategoryWithProducts = Category & { products: ProductInCart[] }

const Services = () => {
  const navigate = useNavigate()
  const { lang } = useParams()

  const { t } = useTranslation(['Main', 'Cart'])

  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [lang])

  const translations = useTypedSelector(state => state.translations)

  const serviceProvider = useTypedSelector(state => state.airtableData.serviceProvider)
  const allProducts = useTypedSelector(state => state.airtableData.products)
  const providerCategories = useTypedSelector(state => state.airtableData.categories).filter(
    cat => cat.name !== CATEGORIES.CoWorkingSpaces
  )

  const getUpsellProducts = (productIds: string[]) => {
    const upsellProducts = allProducts.filter(product => {
      if (productIds.find(id => id === product.id)) {
        if (product !== undefined) {
          return product
        }
      }
    })
    return upsellProducts.length > 0 ? upsellProducts : []
  }

  const upsellAccommodation = getUpsellProducts(
    Array.isArray(serviceProvider.fields['Upsell Accommodation'])
      ? serviceProvider.fields['Upsell Accommodation']
      : []
  )
  const upsellFoodAndBeverages = getUpsellProducts(
    Array.isArray(serviceProvider.fields['Upsell Food & Beverages'])
      ? serviceProvider.fields['Upsell Food & Beverages']
      : []
  )
  const upsellActivities = getUpsellProducts(
    Array.isArray(serviceProvider.fields['Upsell Activities'])
      ? serviceProvider.fields['Upsell Activities']
      : []
  )

  const cartProducts = useTypedSelector(state => state.cartSlice.products)

  const categoriesWithProducts = providerCategories.map(providerCategory => {
    const productsFromCategoryInCart = cartProducts.filter(
      product => product.category === providerCategory.name
    )
    return { ...providerCategory, products: productsFromCategoryInCart }
  })

  const getPrice = (item: ProductInCart) => {
    if (item.category === CATEGORIES.Accommodation) {
      return round(item.cartMeta.price * item.productQuantity)
    }
    return round(item.cartMeta.price * item.productQuantity)
  }

  const getOptionsAmount = (category: CategoryWithProducts) => {
    if (category.name === CATEGORIES.Accommodation) {
      if (serviceProvider.fields[category.name]?.length) {
        return serviceProvider.fields[category.name]?.length + upsellAccommodation.length
      } else return upsellAccommodation.length
    }
    if (category.name === CATEGORIES.FoodAndBeverages) {
      if (serviceProvider.fields[category.name]?.length) {
        return serviceProvider.fields[category.name]?.length + upsellFoodAndBeverages.length
      } else return upsellFoodAndBeverages.length
    }
    if (category.name === CATEGORIES.Activities) {
      if (serviceProvider.fields[category.name]?.length) {
        return serviceProvider.fields[category.name]?.length + upsellActivities.length
      } else return upsellActivities.length
    }
    return 0
  }

  return (
    <UqList disablePadding>
      {categoriesWithProducts.map((category: CategoryWithProducts, index) => {
        if (category.products.length === 0) {
          return (
            <UqBox key={category.id}>
              {getOptionsAmount(category) ? (
                <>
                  <UqListItem>
                    <UqListItemText
                      primary={
                        translations[category.name]
                          ? lang === 'en'
                            ? translations[category.name].key
                            : lang === 'fi'
                            ? translations[category.name].fi
                            : translations[category.name].sv
                          : category.name
                      }
                      secondary={`${getOptionsAmount(category)} ${t('Cart:optionsAvailable')}`}
                      primaryTypographyProps={{ color: 'text.primary' }}
                      secondaryTypographyProps={{ color: 'text.secondary' }}
                    />
                    <UqListItemSecondaryAction>
                      <Add
                        variant="single"
                        edge="end"
                        color="secondary"
                        onClick={() => {
                          navigate(`/${lang}/cart/${category.ordinalNumber}`)
                        }}
                      />
                    </UqListItemSecondaryAction>
                  </UqListItem>
                  {providerCategories.length > index + 1 && <UqDivider variant="fullWidth" />}
                </>
              ) : null}
            </UqBox>
          )
        } else {
          return (
            <UqBox key={index}>
              <UqListItem>
                <UqListItemText
                  primary={
                    translations[category.name]
                      ? lang === 'en'
                        ? translations[category.name].key
                        : lang === 'fi'
                        ? translations[category.name].fi
                        : translations[category.name].sv
                      : category.name
                  }
                  primaryTypographyProps={{ color: 'text.primary' }}
                />
              </UqListItem>
              <UqBox marginHorizontal={16}>
                <UqList disablePadding={true}>
                  {category.products.map(item => {
                    return (
                      <UqListItem
                        style={{
                          marginBottom: 16,
                          backgroundColor: '#fdfbf5',
                          border: '1px solid #80808040'
                        }}
                        key={item.productId}
                      >
                        <UqListItemText
                          primary={
                            item.cartMeta.price
                              ? `${
                                  translations[item.cartMeta.name]
                                    ? lang === 'en'
                                      ? translations[item.cartMeta.name].key
                                      : lang === 'fi'
                                      ? translations[item.cartMeta.name].fi
                                      : translations[item.cartMeta.name].sv
                                    : item.cartMeta.name
                                }: ${item.productQuantity} x ${round(item.cartMeta.price)}`
                              : `${
                                  translations[item.cartMeta.name]
                                    ? lang === 'en'
                                      ? translations[item.cartMeta.name].key
                                      : lang === 'fi'
                                      ? translations[item.cartMeta.name].fi
                                      : translations[item.cartMeta.name].sv
                                    : item.cartMeta.name
                                }`
                          }
                          secondary={
                            item.category !== CATEGORIES.Activities && (
                              <>
                                {item.cartMeta.startDate && (
                                  <UqTypography
                                    variant="body2"
                                    color="text.secondary"
                                    component="span" // needed for validate DOM-nesting
                                    sx={{ display: 'block' }} // since component is 'span', set display to block
                                  >
                                    {item.cartMeta.startDate} - {item.cartMeta.endDate}
                                  </UqTypography>
                                )}
                                {item.category === CATEGORIES.FoodAndBeverages ? (
                                  <UqTypography
                                    variant="body2"
                                    color="text.secondary"
                                    component="span" // needed for validate DOM-nesting
                                    sx={{ display: 'block' }} // since component is 'span', set display to block
                                  >
                                    {item.productQuantity}{' '}
                                    {translations[item.cartMeta.unit]
                                      ? lang === 'en'
                                        ? translations[item.cartMeta.unit].key
                                        : lang === 'fi'
                                        ? translations[item.cartMeta.unit].fi
                                        : translations[item.cartMeta.unit].sv
                                      : item.cartMeta.unit}
                                  </UqTypography>
                                ) : (
                                  <UqTypography
                                    variant="body2"
                                    color="text.secondary"
                                    component="span" // needed for validate DOM-nesting
                                    sx={{ display: 'block' }} // since component is 'span', set display to block
                                  >
                                    {item.cartMeta.guests} {t('Main:guests')}
                                  </UqTypography>
                                )}
                                <UqTypography
                                  variant="body2"
                                  color="text.secondary"
                                  component="span" // needed for validate DOM-nesting
                                  sx={{ display: 'block' }} // since component is 'span', set display to block
                                >
                                  {getPrice(item)}
                                </UqTypography>
                              </>
                            )
                          }
                          primaryTypographyProps={{ color: 'text.primary' }}
                          secondaryTypographyProps={{ color: 'text.secondary' }}
                        />
                        <UqListItemSecondaryAction>
                          <UqTypography
                            color="secondary"
                            variant="button"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              if (item.category === CATEGORIES.Activities) {
                                navigate(`/${lang}/cart/${category.ordinalNumber}`)
                              } else
                                navigate(
                                  `/${lang}/cart/${category.ordinalNumber}/${item.productId}`
                                )
                            }}
                          >
                            {t('Main:edit')}
                          </UqTypography>
                        </UqListItemSecondaryAction>
                      </UqListItem>
                    )
                  })}
                </UqList>
              </UqBox>
              <UqBox
                style={{ display: 'flex', alignItems: 'center' }}
                marginBottom={16}
                marginTop={8}
                marginHorizontal={16}
              >
                <UqButton
                  variant="text"
                  color="secondary"
                  startIcon={<AddIcon />}
                  onClick={() => {
                    navigate(`/${lang}/cart/${category.ordinalNumber}`)
                  }}
                >
                  {t('Main:addNew')}
                </UqButton>
              </UqBox>
              {providerCategories.length > index + 1 && <UqDivider variant="fullWidth" />}
            </UqBox>
          )
        }
      })}
    </UqList>
  )
}

export default Services
