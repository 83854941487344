import { useState, useEffect } from 'react'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import { Layout, FlowNav } from '@uniqore/module'
import AppBarMenu from 'Components/Common/AppBarMenu'
import {
  UqTypography,
  UqTextField,
  UqFormControl,
  UqFormControlLabel,
  UqSwitch,
  UqCollapse
} from '@uniqore/wrapper'
import { layoutProps } from 'Theme'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { updateContactDetails } from 'Features/ContactDetailsSlice'
import { useTypedSelector } from 'Store'
import { validateEmailRegex } from 'Util/Regex'
import { URLS } from 'App'

const ContactDetailsPage = () => {
  const navigate = useNavigate()
  const { lang } = useParams()
  const dispatch = useDispatch()
  const [isSameContactPerson, setIsSameContactPerson] = useState(true)
  const [emailError, setEmailError] = useState('')
  const [otherContactEmailError, setOtherContactEmailError] = useState('')

  const { t } = useTranslation(['Main', 'ContactDetails'])

  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [lang])

  const personDetails = useTypedSelector(state => state.contactDetails.orderPerson)
  const otherContactDetails = useTypedSelector(state => state.contactDetails.contactPerson)

  const validateEmail = (mail: string) => {
    if (validateEmailRegex.test(mail)) {
      return true
    }

    if (mail === personDetails.email) {
      setEmailError(t('Main:errorInputEmail'))
    }

    if (mail === otherContactDetails.email) {
      setOtherContactEmailError(t('Main:errorInputEmail'))
    }
    return false
  }

  const addContactPerson = () => {
    setIsSameContactPerson(!isSameContactPerson)
  }

  const savePersonDetails = () => {
    const mainPersonEmail = personDetails.email.trim().toLowerCase()
    const contactPersonEmail = otherContactDetails.email?.trim().toLowerCase()
    const validatedEmails = isSameContactPerson
      ? validateEmail(mainPersonEmail) === true
      : validateEmail(mainPersonEmail) === true && validateEmail(contactPersonEmail) === true

    if (isSameContactPerson) {
      dispatch(
        updateContactDetails({
          key: 'contactPerson',
          value: {
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: ''
          }
        })
      )
    }
    validatedEmails && navigate(`/${lang}/${URLS.SummaryPage}`)
  }

  const filledPersonDetails =
    personDetails.firstName &&
    personDetails.lastName &&
    personDetails.email &&
    personDetails.phoneNumber

  const filledotherContactDetails =
    otherContactDetails.firstName &&
    otherContactDetails.lastName &&
    otherContactDetails.email &&
    otherContactDetails.phoneNumber

  const allFieldsFilled = filledPersonDetails && filledotherContactDetails

  const disableGoForwardButton = () => {
    if (isSameContactPerson && filledPersonDetails) {
      return false
    }

    if (!isSameContactPerson && allFieldsFilled) {
      return false
    }
    return true
  }

  return (
    <Layout
      {...layoutProps}
      scrollableContent={true}
      showFooter
      top={<AppBarMenu title={t('Main:contactDetails')}></AppBarMenu>}
      bottom={
        <FlowNav
          actionDirection="row"
          actionComponents={[
            {
              text: t('Main:back'),
              variant: 'text',
              color: 'primary',
              onClick: () => navigate(`/${lang}/${URLS.CompanyDetailsPage}`),
              fullWidth: true
            },
            {
              text: t('Main:next'),
              variant: 'contained',
              color: 'primary',
              onClick: () => savePersonDetails(),
              fullWidth: true,
              disabled: disableGoForwardButton()
            }
          ]}
        />
      }
    >
      <UqTypography variant="h6">{t('Main:contactDetails')}</UqTypography>
      <UqTextField
        label={t('ContactDetails:firstName')}
        fullWidth
        marginVertical={16}
        autoComplete="given-name"
        value={personDetails.firstName}
        onChange={e =>
          dispatch(
            updateContactDetails({
              key: 'orderPerson',
              value: {
                ...personDetails,
                firstName: e.target.value
              }
            })
          )
        }
      />
      <UqTextField
        label={t('ContactDetails:surName')}
        fullWidth
        marginBottom={16}
        autoComplete="family-name"
        value={personDetails.lastName}
        onChange={e =>
          dispatch(
            updateContactDetails({
              key: 'orderPerson',
              value: {
                ...personDetails,
                lastName: e.target.value
              }
            })
          )
        }
      />
      <UqTextField
        label={t('ContactDetails:email')}
        fullWidth
        marginBottom={16}
        autoComplete="email"
        error={emailError ? true : false}
        helperText={emailError}
        value={personDetails.email}
        onChange={e => {
          dispatch(
            updateContactDetails({
              key: 'orderPerson',
              value: {
                ...personDetails,
                email: e.target.value
              }
            })
          )
        }}
      />
      <UqTextField
        label={t('ContactDetails:phoneNumber')}
        fullWidth
        marginBottom={16}
        type="tel"
        autoComplete="tel"
        value={personDetails.phoneNumber}
        onChange={e =>
          dispatch(
            updateContactDetails({
              key: 'orderPerson',
              value: {
                ...personDetails,
                phoneNumber: e.target.value
              }
            })
          )
        }
      />
      <UqFormControl component="fieldset" variant="standard">
        <UqFormControlLabel
          control={<UqSwitch checked={isSameContactPerson} onChange={addContactPerson} />}
          label={t('ContactDetails:theSameContactPerson')}
        />
      </UqFormControl>
      <UqCollapse in={!isSameContactPerson}>
        <>
          <UqTypography
            variant="h6"
            color="text.primary"
            align="left"
            marginTop={32}
            marginBottom={16}
          >
            {t('ContactDetails:contactPerson')}
          </UqTypography>
          <UqTextField
            label={t('ContactDetails:firstName')}
            fullWidth
            marginBottom={16}
            value={otherContactDetails.firstName}
            onChange={e =>
              dispatch(
                updateContactDetails({
                  key: 'contactPerson',
                  value: {
                    ...otherContactDetails,
                    firstName: e.target.value
                  }
                })
              )
            }
          />
          <UqTextField
            label={t('ContactDetails:surName')}
            fullWidth
            marginBottom={16}
            value={otherContactDetails.lastName}
            onChange={e =>
              dispatch(
                updateContactDetails({
                  key: 'contactPerson',
                  value: {
                    ...otherContactDetails,
                    lastName: e.target.value
                  }
                })
              )
            }
          />
          <UqTextField
            label={t('ContactDetails:email')}
            error={otherContactEmailError ? true : false}
            helperText={otherContactEmailError}
            fullWidth
            marginBottom={16}
            value={otherContactDetails.email}
            onChange={e =>
              dispatch(
                updateContactDetails({
                  key: 'contactPerson',
                  value: {
                    ...otherContactDetails,
                    email: e.target.value
                  }
                })
              )
            }
          />
          <UqTextField
            type="tel"
            label={t('ContactDetails:phoneNumber')}
            fullWidth
            marginBottom={16}
            value={otherContactDetails.phoneNumber}
            onChange={e =>
              dispatch(
                updateContactDetails({
                  key: 'contactPerson',
                  value: {
                    ...otherContactDetails,
                    phoneNumber: e.target.value
                  }
                })
              )
            }
          />
        </>
      </UqCollapse>
    </Layout>
  )
}

export default ContactDetailsPage
