import { useEffect, useReducer, useState } from 'react'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import { Add, Layout, FlowNav } from '@uniqore/module'
import AppBarMenu from 'Components/Common/AppBarMenu'
import {
  UqBox,
  UqList,
  UqListItemText,
  UqListItemAvatar,
  UqTypography,
  UqListItem,
  UqDivider,
  UqListItemSecondaryAction,
  UqAvatar,
  UqSwitch
} from '@uniqore/wrapper'
import { layoutProps } from 'Theme'
import { Product, ProductWithQuantity } from 'Types/Common'
import ProductPage from 'Pages/EndUserPages/ProductPage'
import { useNavigate, useParams } from 'react-router-dom'
import { URLS } from 'App'
import { round } from 'Components/Cart/Helpers/RoundPrice'
import useTypedSelector from 'Store'
import { reducer } from 'Components/Cart/Helpers/ChangeQuantity'
import { useDispatch } from 'react-redux'
import { addProduct, ProductInCart, removeProduct } from 'Features/CartSlice'
import { CATEGORIES } from 'App'
import { setActiveCategory } from 'Features/AirtableSlice'
import { getDays, getNights } from 'Util/getDatesNightsBetween'
import BedIcon from '@mui/icons-material/Bed'
import RestaurantIcon from '@mui/icons-material/Restaurant'
import RowingIcon from '@mui/icons-material/Rowing'
import { isDateBetween, getDates } from 'Util/dateFunctions'

interface ProductsFromCategoriesProps {
  productsFromCategory: Product[]
  productId: string | undefined
}

const ProductsFromCategories = (props: ProductsFromCategoriesProps) => {
  const { productsFromCategory, productId } = props
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { id, lang } = useParams()
  const activeOptionOrdinalNumber = id
  const [showProduct, setShowProduct] = useState<boolean>(false)

  const guests = useTypedSelector(state => state.order.preFilledOrderRequest.guests)

  const activeCategory = useTypedSelector(state => state.airtableData.activeCategory)
  const [activeProduct, setActiveProduct] = useState<ProductWithQuantity | null>(null)
  const providerCategories = useTypedSelector(state => state.airtableData.categories)
  const cartProducts = useTypedSelector(state => state.cartSlice.products)
  const translations = useTypedSelector(state => state.translations)

  const serviceProvider = useTypedSelector(state => state.airtableData.serviceProvider)

  const { t } = useTranslation(['Main', 'Cart'])

  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [lang])

  useEffect(() => {
    const category = providerCategories.filter(
      option => option.ordinalNumber === Number(activeOptionOrdinalNumber)
    )[0]
    if (category === undefined) {
      dispatch(
        setActiveCategory({
          activeCategory: {
            id: '',
            name: '',
            description: ''
          }
        })
      )
      navigate(`/${lang}/${URLS.CartPage}`)
    } else dispatch(setActiveCategory({ activeCategory: category }))
  }, [activeOptionOrdinalNumber, activeCategory])

  const switchActiveOption = (action: 'prev' | 'next') => {
    if (action === 'next' && activeCategory?.ordinalNumber) {
      navigate(`/${lang}/cart/${activeCategory?.ordinalNumber + 1}`)
    }

    if (action === 'prev' && activeCategory?.ordinalNumber) {
      if (activeCategory?.ordinalNumber === 1) {
        navigate(`/${lang}/cart`)
      } else navigate(`/${lang}/cart/${activeCategory?.ordinalNumber - 1}`)
    }
  }

  const [reducerProducts, dispatchReducer] = useReducer(
    reducer,
    productsFromCategory?.map(item => {
      let quantity = 0
      cartProducts.forEach(cartProduct => {
        if (item.id === cartProduct.productId) {
          quantity = cartProduct.productQuantity
        }
      })
      return {
        ...item,
        quantity,
        maxGuests: guests
      }
    })
  )

  const products = reducerProducts as ProductWithQuantity[]

  const checkSeason = (product: ProductWithQuantity, date: Date) => {
    const highSeasonStart = product.metadata.highSeasonStart[0].split('.')
    const highSeasonStartDay = highSeasonStart[0]
    const highSeasonStartMonth = highSeasonStart[1]
    const highSeasonEnd = product.metadata.highSeasonEnd[0].split('.')
    const highSeasonEndDay = highSeasonEnd[0]
    const highSeasonEndMonth = highSeasonEnd[1]

    const highSeasonStartFullDate = new Date(
      `${highSeasonStartMonth}.${highSeasonStartDay}.${date.getFullYear()}`
    )
    const highSeasonEndFullDate = new Date(
      `${highSeasonEndMonth}.${highSeasonEndDay}.${date.getFullYear()}`
    )

    if (isDateBetween(date, highSeasonStartFullDate, highSeasonEndFullDate)) {
      return true
    }

    return false
  }

  const getAccommodationPricePerNight = (
    productInCart: ProductInCart | undefined,
    product: ProductWithQuantity,
    dates: Date[]
  ) => {
    const nights = productInCart ? getNights(productInCart) : 1
    let totalPrice = 0
    dates.forEach(date => {
      const isHighSeason = checkSeason(product, date)
      const minNightsForPricePerWeek = 5
      const minNightsForPricePerMonth = 20
      if (isHighSeason) {
        if (nights < minNightsForPricePerWeek && 'priceNightHigh' in product.metadata.price) {
          totalPrice += product.metadata.price.priceNightHigh
        }

        if (
          nights >= minNightsForPricePerWeek &&
          nights < minNightsForPricePerMonth &&
          'priceWeekHigh' in product.metadata.price
        ) {
          totalPrice += product.metadata.price.priceWeekHigh
        }

        if (nights >= minNightsForPricePerMonth && 'priceMonthHigh' in product.metadata.price) {
          totalPrice += product.metadata.price.priceMonthHigh
        }
      } else {
        if (nights < minNightsForPricePerWeek && 'priceNightLow' in product.metadata.price) {
          totalPrice += product.metadata.price.priceNightLow
        }

        if (
          nights >= minNightsForPricePerWeek &&
          nights < minNightsForPricePerMonth &&
          'priceWeekLow' in product.metadata.price
        ) {
          totalPrice += product.metadata.price.priceWeekLow
        }

        if (nights >= minNightsForPricePerMonth && 'priceMonthLow' in product.metadata.price) {
          totalPrice += product.metadata.price.priceMonthLow
        }
      }
    })
    return totalPrice / nights
  }

  const getPrice = (productInCart: ProductInCart | undefined, product: ProductWithQuantity) => {
    if (productInCart?.cartMeta.startDate && productInCart?.cartMeta.endDate) {
      const startDateParts = productInCart?.cartMeta.startDate.split('.')
      const startDate = new Date(`${startDateParts[2]}-${startDateParts[1]}-${startDateParts[0]}`)
      const endDateParts = productInCart?.cartMeta.endDate.split('.')
      const endDate = new Date(`${endDateParts[2]}-${endDateParts[1]}-${endDateParts[0]}`)
      const dates = getDates(startDate, endDate)
      const pricePerNight = getAccommodationPricePerNight(productInCart, product, dates)
      const nights = productInCart ? getNights(productInCart) : 1
      return pricePerNight * nights
    }
    return 0
  }

  useEffect(() => {
    const productsWitthoutActivities = products.filter(
      product => product.metadata.category !== CATEGORIES.Activities
    )
    dispatch(
      addProduct(
        productsWitthoutActivities.map(product => {
          const productInCart = cartProducts.find(
            cartProduct => cartProduct.productId == product.id
          )
          if (product.metadata.category === CATEGORIES.FoodAndBeverages) {
            return {
              category: product.metadata.category,
              productId: product.id,
              productQuantity: product.quantity,
              cartMeta: {
                name: product.name,
                price: product.variants[0]?.metadata?.numericPrice ?? 0,
                taxPercent: product.metadata.vat,
                taxAmount: product.metadata.vat * product.variants[0]?.metadata?.numericPrice ?? 0,
                priceWithoutTax:
                  product.metadata.category !== CATEGORIES.Activities
                    ? product.variants[0]?.metadata?.numericPrice -
                      product.metadata.vat * product.variants[0].metadata.numericPrice
                    : 0,
                option: product.variants[0]?.name ?? '',
                productImage: '',
                catalogImage: '',
                sku: `${product.id}-${product.name}`,
                unit: product.variants[0]?.metadata.unit,
                startDate: productInCart?.cartMeta.startDate ?? '',
                endDate: productInCart?.cartMeta.endDate ?? '',
                guests: productInCart?.cartMeta.guests ?? 0,
                days: productInCart ? getDays(productInCart) : 0,
                nights: productInCart ? getNights(productInCart) : 0,
                serviceProviderName: product.metadata.serviceProviderName
                  ? product.metadata.serviceProviderName[0]
                  : '',
                serviceProviderId: product.metadata.serviceProviders
                  ? product.metadata.serviceProviders[0]
                  : '',
                serviceProviderEmail: product.metadata.serviceProviderEmail
                  ? product.metadata.serviceProviderEmail[0]
                  : ''
              }
            }
          } else
            return {
              category: product.metadata?.category,
              productId: product.id,
              productQuantity: product.quantity,
              cartMeta: {
                name: product.name,
                price: getPrice(productInCart, product),
                taxPercent: product.metadata.vat,
                taxAmount: product.metadata.vat * getPrice(productInCart, product),
                priceWithoutTax:
                  getPrice(productInCart, product) -
                  product.metadata.vat * getPrice(productInCart, product),
                option: product.name,
                productImage: '',
                catalogImage: '',
                sku: `${product.id}-${product.name.replaceAll('-', '%20')}-${getPrice(
                  productInCart,
                  product
                )}-night`,
                unit: 'night',
                startDate: productInCart?.cartMeta.startDate ?? '',
                endDate: productInCart?.cartMeta.endDate ?? '',
                guests: productInCart?.cartMeta.guests ?? 0,
                days: productInCart ? getDays(productInCart) : 0,
                nights: productInCart ? getNights(productInCart) : 0,
                serviceProviderName: product.metadata.serviceProviderName
                  ? product.metadata.serviceProviderName[0]
                  : '',
                serviceProviderId: product.metadata.serviceProviders
                  ? product.metadata.serviceProviders[0]
                  : '',
                serviceProviderEmail: product.metadata.serviceProviderEmail
                  ? product.metadata.serviceProviderEmail[0]
                  : ''
              }
            }
        })
      )
    )
  }, [products])

  useEffect(() => {
    if (productId) {
      const product = products.find(product => product.id === productId)
      if (product) {
        setActiveProduct(product)
        setShowProduct(true)
      }
    }
  }, [productId])

  const activeProductQuantity = activeProduct
    ? products.find(i => i.id === activeProduct.id)?.quantity || 0
    : 0

  const [checkedActivities, setCheckedActivities] = useState<ProductWithQuantity[]>([])

  const handleChangeActivities = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentActivity = products.find(product => product.name === event.target.value)
    if (currentActivity) {
      if (event.target.checked) {
        setCheckedActivities([...checkedActivities, currentActivity])
        dispatch(
          addProduct(
            [currentActivity].map(product => ({
              category: product.metadata.category,
              productId: product.id,
              productQuantity: 1,
              cartMeta: {
                name: product.name,
                price: 0,
                taxPercent: 0,
                taxAmount: 0,
                priceWithoutTax: 0,
                option: '',
                productImage: '',
                catalogImage: '',
                sku: product.variants[0]?.sku ?? '',
                unit: product.variants[0]?.metadata.unit,
                serviceProviderId: product.metadata.serviceProviders[0],
                serviceProviderEmail: product.metadata.serviceProviderEmail[0],
                serviceProviderName: product.metadata.serviceProviderName[0]
              }
            }))
          )
        )
      } else {
        dispatch(removeProduct({ productId: currentActivity.id }))
        setCheckedActivities(checkedActivities.filter(act => act.id !== currentActivity.id))
      }
    }
  }

  const translatedActiveCategoryName = translations[activeCategory?.name]
    ? lang === 'en'
      ? translations[activeCategory.name].key
      : lang === 'fi'
      ? translations[activeCategory.name].fi
      : translations[activeCategory.name].sv
    : activeCategory.name

  const getTranslatedUnit = (item: ProductWithQuantity) => {
    if (item.metadata.category === CATEGORIES.Accommodation) {
      return t('Main:night')
    } else
      return translations[item.variants[0].metadata.unit]
        ? lang === 'en'
          ? translations[item.variants[0].metadata.unit].key
          : lang === 'fi'
          ? translations[item.variants[0].metadata.unit].fi
          : translations[item.variants[0].metadata.unit].sv
        : item.variants[0].metadata.unit
  }

  const getTranslatedItemName = (item: ProductWithQuantity) => {
    return translations[item.name]
      ? lang === 'en'
        ? translations[item.name].key
        : lang === 'fi'
        ? translations[item.name].fi
        : translations[item.name].sv
      : item.name
  }

  const productsFromMainProvider = products.filter(
    product =>
      product.metadata.serviceProviders &&
      product.metadata.serviceProviders[0] === serviceProvider.id
  )

  const upsellProducts = products.filter(
    product =>
      product.metadata.serviceProviders &&
      product.metadata.serviceProviders[0] !== serviceProvider.id
  )
  const upsellProviderNames = upsellProducts.map(product => {
    return {
      name: product.metadata.serviceProviderName[0],
      description: product.metadata.serviceProviderDescription[0]
    }
  })

  const uniqueProviderNames = Array.from(
    new Set(
      upsellProviderNames.map((item: { name: string; description: string }) => JSON.stringify(item))
    )
  ).map(item => JSON.parse(item))

  const upsellProvidersWithProducts = uniqueProviderNames.map(provider => {
    return {
      provider: provider,
      products: upsellProducts.filter(
        product => product.metadata.serviceProviderName[0] === provider.name
      )
    }
  })

  return showProduct && activeProduct ? (
    <ProductPage
      setShowProduct={setShowProduct}
      product={activeProduct}
      dispatchReducer={dispatchReducer}
      activeProductQuantity={activeProductQuantity}
    />
  ) : (
    <Layout
      {...layoutProps}
      scrollableContent={true}
      showFooter
      topSticky={
        <AppBarMenu
          title={translatedActiveCategoryName}
          color="inherit"
          showBottomBorder={true}
          hasCloseIcon={true}
          setShowProduct={setShowProduct}
        ></AppBarMenu>
      }
      bottomSticky={
        <FlowNav
          actionComponents={[
            {
              text: t('Main:back'),
              variant: 'text',
              color: 'primary',
              onClick: () => switchActiveOption('prev'),
              fullWidth: true
            },
            {
              text: t('Main:next'),
              variant: 'contained',
              color: 'primary',
              onClick: () => switchActiveOption('next'),
              fullWidth: true
            }
          ]}
        />
      }
    >
      <UqBox>
        <UqTypography variant="h6">{translatedActiveCategoryName}</UqTypography>
        <UqTypography variant="body1">
          {translations[activeCategory.description]
            ? lang === 'en'
              ? translations[activeCategory.description].key
              : lang === 'fi'
              ? translations[activeCategory.description].fi
              : translations[activeCategory.description].sv
            : activeCategory.description}
        </UqTypography>
        <UqDivider variant="fullWidth" marginVertical={16} />
        {productsFromMainProvider.length > 0 && (
          <UqList disablePadding>
            <UqListItem key={serviceProvider.id}>
              <UqListItemText
                primary={serviceProvider.fields.Name}
                secondary={serviceProvider.fields.Description}
                primaryTypographyProps={{ color: 'text.primary' }}
              />
            </UqListItem>
          </UqList>
        )}
        <UqList disablePadding>
          {productsFromMainProvider?.map((item, index, arr) => {
            if (item.metadata.category === CATEGORIES.Accommodation) {
              return (
                <UqListItem
                  key={item.id}
                  onClick={() => {
                    setActiveProduct(item)
                    setShowProduct(true)
                  }}
                >
                  <UqListItemAvatar>
                    <UqAvatar variant="square" sx={{ bgcolor: '#002444' }}>
                      <BedIcon />
                    </UqAvatar>
                  </UqListItemAvatar>
                  <UqListItemText
                    primary={getTranslatedItemName(item)}
                    secondary={
                      round(item.metadata.price.priceNightLow) + ' / ' + getTranslatedUnit(item)
                    }
                    primaryTypographyProps={{ color: 'text.primary' }}
                    secondaryTypographyProps={{ color: 'text.secondary' }}
                  />
                  <UqListItemSecondaryAction>
                    <Add
                      variant="multi"
                      edge="end"
                      color="secondary"
                      unit={t('Main:pieces')}
                      quantity={item.quantity}
                    />
                  </UqListItemSecondaryAction>
                </UqListItem>
              )
            } else if (item.metadata.category === CATEGORIES.FoodAndBeverages) {
              const nextItem = arr[index + 1]
              const nextItemName = nextItem?.name.split(' ')[0]
              return (
                <UqBox key={item.id}>
                  <UqListItem
                    onClick={() => {
                      setActiveProduct(item)
                      setShowProduct(true)
                    }}
                  >
                    <UqListItemAvatar>
                      <UqAvatar variant="square" sx={{ bgcolor: '#002444' }}>
                        <RestaurantIcon />
                      </UqAvatar>
                    </UqListItemAvatar>
                    <UqListItemText
                      primary={item.name + ' / ' + getTranslatedUnit(item)}
                      secondary={
                        round(item.variants[0].metadata.numericPrice) +
                        ' / ' +
                        getTranslatedUnit(item)
                      }
                      primaryTypographyProps={{ color: 'text.primary' }}
                      secondaryTypographyProps={{ color: 'text.secondary' }}
                    />
                    <UqListItemSecondaryAction>
                      <Add
                        variant="multi"
                        edge="end"
                        color="secondary"
                        unit={t('Main:pieces')}
                        quantity={item.quantity}
                      />
                    </UqListItemSecondaryAction>
                  </UqListItem>
                  {nextItemName !== undefined && item.name !== nextItemName && (
                    <UqDivider variant="fullWidth" marginVertical={16} />
                  )}
                </UqBox>
              )
            } else {
              const itemInCart = cartProducts.find(product => product.productId === item.id)
              return (
                <UqListItem key={item.id}>
                  <UqListItemAvatar>
                    <UqAvatar variant="square" sx={{ bgcolor: '#002444' }}>
                      <RowingIcon />
                    </UqAvatar>
                  </UqListItemAvatar>
                  <UqListItemText
                    primary={getTranslatedItemName(item)}
                    primaryTypographyProps={{ color: 'text.primary' }}
                    secondaryTypographyProps={{ color: 'text.secondary' }}
                  />
                  <UqListItemSecondaryAction>
                    <UqSwitch
                      checked={
                        itemInCart || checkedActivities.some(activity => activity.id === item.id)
                          ? true
                          : false
                      }
                      onChange={handleChangeActivities}
                      value={getTranslatedItemName(item)}
                    />
                  </UqListItemSecondaryAction>
                </UqListItem>
              )
            }
          })}
        </UqList>
        {upsellProvidersWithProducts?.map(providerWithProducts => (
          <UqBox key={providerWithProducts.provider.name}>
            <UqList disablePadding marginTop={16}>
              <UqListItem>
                <UqListItemText
                  primary={providerWithProducts.provider.name}
                  secondary={providerWithProducts.provider.description}
                  primaryTypographyProps={{ color: 'text.primary' }}
                />
              </UqListItem>
            </UqList>
            <UqList disablePadding>
              {providerWithProducts.products?.map((item, index, arr) => {
                if (item.metadata.category === CATEGORIES.Accommodation) {
                  return (
                    <UqListItem
                      key={item.id}
                      onClick={() => {
                        setActiveProduct(item)
                        setShowProduct(true)
                      }}
                    >
                      <UqListItemAvatar>
                        <UqAvatar variant="square" sx={{ bgcolor: '#002444' }}>
                          <BedIcon />
                        </UqAvatar>
                      </UqListItemAvatar>
                      <UqListItemText
                        primary={getTranslatedItemName(item)}
                        secondary={
                          round(item.metadata.price.priceNightLow) + ' / ' + getTranslatedUnit(item)
                        }
                        primaryTypographyProps={{ color: 'text.primary' }}
                        secondaryTypographyProps={{ color: 'text.secondary' }}
                      />
                      <UqListItemSecondaryAction>
                        <Add
                          variant="multi"
                          edge="end"
                          color="secondary"
                          unit={t('Main:pieces')}
                          quantity={item.quantity}
                        />
                      </UqListItemSecondaryAction>
                    </UqListItem>
                  )
                } else if (item.metadata.category === CATEGORIES.FoodAndBeverages) {
                  const nextItem = arr[index + 1]
                  const nextItemName = nextItem?.name.split(' ')[0]
                  return (
                    <UqBox key={item.id}>
                      <UqListItem
                        onClick={() => {
                          setActiveProduct(item)
                          setShowProduct(true)
                        }}
                      >
                        <UqListItemAvatar>
                          <UqAvatar variant="square" sx={{ bgcolor: '#002444' }}>
                            <RestaurantIcon />
                          </UqAvatar>
                        </UqListItemAvatar>
                        <UqListItemText
                          primary={item.name + ' / ' + getTranslatedUnit(item)}
                          secondary={
                            round(item.variants[0].metadata.numericPrice) +
                            ' / ' +
                            getTranslatedUnit(item)
                          }
                          primaryTypographyProps={{ color: 'text.primary' }}
                          secondaryTypographyProps={{ color: 'text.secondary' }}
                        />
                        <UqListItemSecondaryAction>
                          <Add
                            variant="multi"
                            edge="end"
                            color="secondary"
                            unit={t('Main:pieces')}
                            quantity={item.quantity}
                          />
                        </UqListItemSecondaryAction>
                      </UqListItem>
                      {nextItemName !== undefined && item.name !== nextItemName && (
                        <UqDivider variant="fullWidth" marginVertical={16} />
                      )}
                    </UqBox>
                  )
                } else {
                  const itemInCart = cartProducts.find(product => product.productId === item.id)
                  return (
                    <UqListItem key={item.id}>
                      <UqListItemAvatar>
                        <UqAvatar variant="square" sx={{ bgcolor: '#002444' }}>
                          <RowingIcon />
                        </UqAvatar>
                      </UqListItemAvatar>
                      <UqListItemText
                        primary={getTranslatedItemName(item)}
                        primaryTypographyProps={{ color: 'text.primary' }}
                        secondaryTypographyProps={{ color: 'text.secondary' }}
                      />
                      <UqListItemSecondaryAction>
                        <UqSwitch
                          checked={
                            itemInCart ||
                            checkedActivities.some(activity => activity.id === item.id)
                              ? true
                              : false
                          }
                          onChange={handleChangeActivities}
                          value={getTranslatedItemName(item)}
                        />
                      </UqListItemSecondaryAction>
                    </UqListItem>
                  )
                }
              })}
            </UqList>
          </UqBox>
        ))}
      </UqBox>
    </Layout>
  )
}

export default ProductsFromCategories
