import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import {
  UqDialog,
  UqDialogTitle,
  UqDialogContent,
  UqDialogContentText,
  UqDialogActions,
  UqButton
} from '@uniqore/wrapper'
import { URLS } from 'App'
import { Dispatch, SetStateAction, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

interface DialogProps {
  showDialog: boolean
  setShowDialog: Dispatch<SetStateAction<boolean>>
}

const NoServicesDialog = (props: DialogProps) => {
  const navigate = useNavigate()
  const { lang } = useParams()
  const { showDialog, setShowDialog } = props

  const { t } = useTranslation(['Main', 'Cart'])

  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [lang])
  return (
    <UqDialog open={showDialog} fullWidth>
      <UqDialogTitle>{t('Main:noProductsDialogTitle')}</UqDialogTitle>
      <UqDialogContent>
        <UqDialogContentText>{t('Main:noProductsDialogText')}</UqDialogContentText>
      </UqDialogContent>
      <UqDialogActions style={{ marginBottom: 8 }}>
        <UqButton onClick={() => setShowDialog(false)}>{t('Main:goBack')}</UqButton>
        <UqButton
          onClick={() => navigate(`/${lang}/${URLS.CompanyDetailsPage}`)}
          variant="contained"
        >
          {t('Main:yesContinue')}
        </UqButton>
      </UqDialogActions>
    </UqDialog>
  )
}

export default NoServicesDialog
