export const isDateBetween = (date: Date, start: Date, end: Date) => {
  const dateMs = date.getTime()
  const startMs = start.getTime()
  const endMs = end.getTime()

  return dateMs >= startMs && dateMs <= endMs
}

export const getDiff = (arrivingDate: Date, leavingDate: Date) => {
  const startTimestamp = arrivingDate.getTime()
  const endTimestamp = leavingDate.getTime()
  const diff = endTimestamp - startTimestamp
  return diff
}

export const getDates = (startDate: Date, endDate: Date): Date[] => {
  const allDates = Array.from(
    { length: (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24) + 1 },
    (_, i) => new Date(startDate.getTime() + i * 1000 * 60 * 60 * 24)
  )
  const allNights = allDates.slice(0, -1)
  return allNights
}
