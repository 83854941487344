export const getMainWebsiteUrl = (lang: string) => {
  switch (lang) {
    case 'fi':
      return process.env.REACT_APP_MAIN_WEBSITE_URL_FI
    case 'sv':
      return process.env.REACT_APP_MAIN_WEBSITE_URL_SV
    case 'en':
      return process.env.REACT_APP_MAIN_WEBSITE_URL_EN
    default:
      return process.env.REACT_APP_MAIN_WEBSITE_URL_FI
  }
}
