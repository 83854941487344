import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { FETCH_DATAVIEW } from 'GraphQL/queries'
import { useQuery } from '@apollo/client'
import { setTranslations, AirtableTranslate } from 'Features/TranslationsSlice'
import { editSnackbar } from 'Features/snackbar'

interface AirtableTranslationsDataViewData {
  dataview: {
    data: {
      records: AirtableTranslate[]
    }
  }
}

export const useTranslations = () => {
  const dispatch = useDispatch()
  const { data, loading } = useQuery<AirtableTranslationsDataViewData>(FETCH_DATAVIEW, {
    variables: {
      id: process.env.REACT_APP_DATAVIEW_GET_TRANSLATIONS_ID
    },
    onError(err) {
      dispatch(editSnackbar({ message: err.message, type: 'error' }))
    }
  })

  useEffect(() => {
    if (data) {
      dispatch(setTranslations({ airtableTranslations: data.dataview.data.records }))
    }
  }, [data])
  return { loading }
}
