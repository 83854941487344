import { useEffect } from 'react'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import { Layout, FlowNav } from '@uniqore/module'
import AppBarMenu from 'Components/Common/AppBarMenu'
import { UqTypography, UqTextField } from '@uniqore/wrapper'
import { layoutProps } from 'Theme'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { URLS } from 'App'
import { updateContactDetails } from 'Features/ContactDetailsSlice'
import { useTypedSelector } from 'Store'

const CompanyDetailsPage = () => {
  const navigate = useNavigate()
  const { lang } = useParams()
  const dispatch = useDispatch()
  const companyDetails = useTypedSelector(state => state.contactDetails.companyDetails)

  const { t } = useTranslation(['Main', 'ContactDetails'])

  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [lang])

  const filledCompanyDetails =
    companyDetails.name &&
    companyDetails.businessId &&
    companyDetails.address &&
    companyDetails.zipCode &&
    companyDetails.city &&
    companyDetails.country
  return (
    <Layout
      {...layoutProps}
      showFooter
      top={<AppBarMenu title={t('Main:companyDetails')}></AppBarMenu>}
      bottom={
        <FlowNav
          actionDirection="row"
          actionComponents={[
            {
              text: t('Main:back'),
              variant: 'text',
              color: 'primary',
              onClick: () => navigate(`/${lang}/${URLS.CartPage}`),
              fullWidth: true
            },
            {
              text: t('Main:next'),
              variant: 'contained',
              color: 'primary',
              onClick: () => navigate(`/${lang}/${URLS.ContactDetailsPage}`),
              fullWidth: true,
              disabled: !filledCompanyDetails
            }
          ]}
        />
      }
    >
      <UqTypography variant="h6">{t('Main:companyDetails')}</UqTypography>
      <UqTextField
        label={t('ContactDetails:company')}
        fullWidth
        marginVertical={16}
        value={companyDetails.name}
        onChange={e =>
          dispatch(
            updateContactDetails({
              key: 'companyDetails',
              value: {
                ...companyDetails,
                name: e.target.value
              }
            })
          )
        }
      />
      <UqTextField
        label={t('ContactDetails:businessId')}
        fullWidth
        marginBottom={16}
        value={companyDetails.businessId}
        onChange={e =>
          dispatch(
            updateContactDetails({
              key: 'companyDetails',
              value: {
                ...companyDetails,
                businessId: e.target.value
              }
            })
          )
        }
      />
      <UqTextField
        label={t('ContactDetails:address')}
        fullWidth
        marginBottom={16}
        autoComplete="shipping street-address"
        value={companyDetails.address}
        onChange={e => {
          dispatch(
            updateContactDetails({
              key: 'companyDetails',
              value: {
                ...companyDetails,
                address: e.target.value
              }
            })
          )
        }}
      />
      <UqTextField
        label={t('ContactDetails:zip')}
        fullWidth
        marginBottom={16}
        autoComplete="shipping postal-code"
        value={companyDetails.zipCode}
        onChange={e =>
          dispatch(
            updateContactDetails({
              key: 'companyDetails',
              value: {
                ...companyDetails,
                zipCode: e.target.value
              }
            })
          )
        }
      />
      <UqTextField
        label={t('ContactDetails:city')}
        fullWidth
        marginBottom={16}
        autoComplete="shipping locality"
        value={companyDetails.city}
        onChange={e => {
          dispatch(
            updateContactDetails({
              key: 'companyDetails',
              value: {
                ...companyDetails,
                city: e.target.value
              }
            })
          )
        }}
      />
      <UqTextField
        label={t('ContactDetails:country')}
        fullWidth
        marginBottom={16}
        autoComplete="shipping country"
        value={companyDetails.country}
        onChange={e => {
          dispatch(
            updateContactDetails({
              key: 'companyDetails',
              value: {
                ...companyDetails,
                country: e.target.value
              }
            })
          )
        }}
      />
    </Layout>
  )
}

export default CompanyDetailsPage
