import { gql } from '@apollo/client'

export const REQUEST_OTP_LINK = gql`
  mutation requestOtpLink(
    $email: EmailAddress!
    $type: LoginType!
    $organization: ID
    $lang: String
    $path: String
  ) {
    requestOtpLink(
      email: $email
      type: $type
      organization: $organization
      lang: $lang
      path: $path
    ) {
      error
    }
  }
`

export const SEND_OTP_CODE = gql`
  mutation otpLogin($code: String!, $organization: ID) {
    otpLogin(code: $code, organization: $organization) {
      token
      error
      uid
    }
  }
`

export const CHECKOUT_CATALOG_ORDER = gql`
  mutation checkoutCatalogOrder($order: ID!) {
    checkoutCatalogOrder(order: $order) {
      id
      catalog
      state
      items {
        sku
        title
        totalPrice
        unitPrice
        quantity
        accepted
        metadata
      }
      total
      paymentUrl
      metadata
      comments {
        byCustomer
        user {
          id
          email
          firstName
          lastName
          organization {
            id
            name
            slug
          }
          metadata
        }
        timestamp
        comment
      }
    }
  }
`

export const CREATE_CATALOG_ORDER = gql`
  mutation createCatalogOrder(
    $checkout: ID!
    $items: [CatalogOrderItemInput!]
    $metadata: JSONObject
    $checkoutNow: Boolean
  ) {
    createCatalogOrder(
      checkout: $checkout
      items: $items
      metadata: $metadata
      checkoutNow: $checkoutNow
    ) {
      id
      catalog
      state
      items {
        sku
        title
        totalPrice
        accepted
        unitPrice
        quantity
      }
      total
      metadata
    }
  }
`

export const CHANGE_CATALOG_ORDER = gql`
  mutation changeCatalogOrder($order: ID!, $input: CatalogOrderInput!) {
    changeCatalogOrder(order: $order, input: $input) {
      id
      catalog
      state
      items {
        sku
        title
        totalPrice
        unitPrice
        quantity
        tax
        accepted
        metadata
      }
      total
      metadata
      comments {
        byCustomer
        user {
          id
        }
        timestamp
        comment
      }
    }
  }
`

export const UPDATE_CATALOG_ORDER = gql`
  mutation updateCatalogOrder(
    $order: ID!
    $items: [CatalogOrderItemInput!]
    $metadata: JSONObject
  ) {
    updateCatalogOrder(order: $order, items: $items, metadata: $metadata) {
      id
      catalog
      state
      items {
        sku
        title
        totalPrice
        unitPrice
        accepted
        quantity
      }
      total
      paymentUrl
      metadata
      comments {
        byCustomer
        user {
          id
          email
          firstName
          lastName
          organization {
            id
            name
            slug
          }
          metadata
        }
        timestamp
        comment
      }
    }
  }
`

export const COMMENT_CATALOG_ORDER = gql`
  mutation commentCatalogOrder($order: ID!, $comment: String!) {
    commentCatalogOrder(order: $order, comment: $comment) {
      id
      catalog
      state
      items {
        sku
        title
        totalPrice
        unitPrice
        accepted
        quantity
      }
      total
      paymentUrl
      metadata
      comments {
        byCustomer
        user {
          id
          email
          firstName
          lastName
          organization {
            id
            name
            slug
          }
          metadata
        }
        timestamp
        comment
      }
    }
  }
`

export const CANCEL_CATALOG_ORDER = gql`
  mutation cancelCatalogOrder($order: ID!) {
    cancelCatalogOrder(order: $order) {
      id
      catalog
      state
      items {
        sku
        title
        totalPrice
        unitPrice
        quantity
        tax
        accepted
        metadata
      }
      total
      metadata
      comments {
        byCustomer
        user {
          id
          email
          firstName
          lastName
          organization {
            id
            name
            slug
          }
          metadata
        }
        timestamp
        comment
      }
    }
  }
`
