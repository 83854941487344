import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface CartMeta {
  name: string
  price: number
  taxPercent: number
  taxAmount: number
  priceWithoutTax: number
  productImage?: string
  catalogImage?: string
  sku: string
  unit: string
  startDate?: string
  endDate?: string
  guests?: number
  days?: number
  nights?: number
  serviceProviderId: string
  serviceProviderEmail: string
  serviceProviderName?: string
}

export interface ProductInCart {
  category: string
  productId: string
  productQuantity: number
  cartMeta: CartMeta
}

interface CartSchema {
  products: ProductInCart[]
}

const initialState: CartSchema = {
  products: []
}

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addProduct: (state, action: PayloadAction<ProductInCart[]>) => {
      const productsToSave = action.payload
      productsToSave.forEach(productToSave => {
        const productCartIndex = state.products.findIndex(
          p => p.productId === productToSave.productId
        )
        if (productCartIndex !== -1) {
          state.products[productCartIndex] = productToSave
        } else {
          state.products.push(productToSave)
        }
      })
      state.products = state.products.filter(p => p.productQuantity > 0)
    },
    addBookingInfo: (
      state,
      action: PayloadAction<{
        productId: string
        startDate: string
        endDate: string
        guests: number
      }>
    ) => {
      const { productId, startDate, endDate, guests } = action.payload
      state.products = state.products.map(product => {
        if (product.productId === productId) {
          return {
            ...product,
            cartMeta: {
              ...product.cartMeta,
              startDate,
              endDate,
              guests
            }
          }
        }
        return product
      })
    },
    removeProduct: (state, action: PayloadAction<{ productId: string }>) => {
      const productId = action.payload.productId
      state.products = state.products.filter(p => p.productId !== productId)
    },
    cleanCart: state => {
      state.products = initialState.products
    }
  }
})

export const { addProduct, addBookingInfo, removeProduct, cleanCart } = cartSlice.actions
export default cartSlice.reducer
