import { FC, useEffect } from 'react'
import i18n, { t } from 'i18next'
import { useLocation, useParams } from 'react-router-dom'
import { useSpace, useCatalogProducts, useCategories } from 'Util'
import useTypedSelector from 'Store'
import SpacesFromProvider from 'Components/Cart/SpacesFromProvider'
import { Product } from 'Types/Common'
import { CATEGORIES } from 'App'
import { Layout } from '@uniqore/module'
import AppBarMenu from 'Components/Common/AppBarMenu'
import { layoutProps } from 'Theme'
import { UqBackdrop, UqCircularProgress } from '@uniqore/wrapper'
import { setPreFilledOrderRequest } from 'Features/OrderSlice'
import { useDispatch } from 'react-redux'

const SpacePage: FC = () => {
  useEffect(() => {
    if (localStorage.getItem('uq_api_token')) {
      localStorage.removeItem('uq_api_token')
    }
  }, [])

  const { providerId, lang, spaceId } = useParams()
  const location = useLocation()
  const dispatch = useDispatch()
  const locationSearch = new URLSearchParams(location.search)
  const startDate = locationSearch.get('startDate')
  const endDate = locationSearch.get('endDate')
  const guests = Number(locationSearch.get('guests'))

  const allPreFilledOrderData = startDate && endDate && guests
  if (allPreFilledOrderData) {
    dispatch(
      setPreFilledOrderRequest({
        preFilledOrderRequest: {
          startDate,
          endDate,
          guests
        }
      })
    )
  }

  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [lang])

  const { providerLoading } = useSpace(providerId ?? null)
  const { loading: categoriesLoading } = useCategories()
  const providerCategories = useTypedSelector(state => state.airtableData.categories)
  const categoriesIds = providerCategories.map(cat => cat.name)
  const { loading: productsLoading } = useCatalogProducts(
    categoriesIds.length > 0 ? categoriesIds : ['Co-working Spaces'],
    providerId ?? ''
  )

  const products = useTypedSelector(state => state.airtableData.products)
  const spaces = products.filter(
    (product: Product) => product.metadata.category === CATEGORIES.CoWorkingSpaces
  )
  const loading = providerLoading || productsLoading || categoriesLoading

  const spacesWithAllRequiredFields = spaces
    .filter(space => space.name && space.metadata.price.priceDay && space.metadata.pax)
    .sort((a, b) => a.metadata.pax - b.metadata.pax)

  return products.length > 0 ? (
    <SpacesFromProvider spacesFromProvider={spacesWithAllRequiredFields} spaceId={spaceId} />
  ) : (
    <Layout
      {...layoutProps}
      showFooter
      top={<AppBarMenu title={t('Main:chooseSpace')}></AppBarMenu>}
    >
      <UqBackdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
        <UqCircularProgress color="inherit" />
      </UqBackdrop>
    </Layout>
  )
}

export default SpacePage
