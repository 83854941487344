import { FC, useState, useEffect } from 'react'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import { Layout, FlowNav } from '@uniqore/module'
import AppBarMenu from 'Components/Common/AppBarMenu'
import NoServicesDialog from 'Components/Cart/NoServicesDialog'
import BookedSpaceSection from 'Components/Cart/BookedSpaceSection'
import Services from 'Components/Cart/Services'
import { UqDivider } from '@uniqore/wrapper'
import { layoutProps } from 'Theme'
import { useNavigate, useParams } from 'react-router-dom'
import { URLS, CATEGORIES } from 'App'
import useTypedSelector from 'Store'
import { useCart } from 'Util'
import { ProductInCart } from 'Features/CartSlice'

const CartPage: FC = () => {
  const navigate = useNavigate()
  const { lang } = useParams()
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const cartProducts = useTypedSelector(state => state.cartSlice.products)
  const [spacesInCart, setSpacesInCart] = useState<ProductInCart[]>([])

  useEffect(() => {
    const spacesInCart = cartProducts.filter(
      product => product.category === CATEGORIES.CoWorkingSpaces
    )
    setSpacesInCart(spacesInCart)
  }, [cartProducts])

  const { t } = useTranslation(['Cart'])

  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [lang])

  const totalQuantity = cartProducts
    .map(item => item.productQuantity)
    .reduce((prev, next) => prev + next)

  const { cartTotalAmount } = useCart(cartProducts)

  return (
    <Layout
      {...layoutProps}
      showFooter
      top={<AppBarMenu title={t('Cart:yourCart')}></AppBarMenu>}
      bottom={
        <FlowNav
          actionDirection="column"
          actionComponents={[
            {
              text: `${t('Cart:goToCheckout')} (${cartTotalAmount()}) `,
              variant: 'contained',
              color: 'primary',
              onClick: () =>
                totalQuantity > 0
                  ? navigate(`/${lang}/${URLS.CompanyDetailsPage}`)
                  : setShowDialog(true),
              fullWidth: true
            }
          ]}
        />
      }
      scrollableContent={true}
    >
      <>
        <NoServicesDialog showDialog={showDialog} setShowDialog={setShowDialog} />
        <BookedSpaceSection spaces={spacesInCart} />
        <UqDivider variant="fullWidth" />
        <Services />
      </>
    </Layout>
  )
}

export default CartPage
