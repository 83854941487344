import { FC, useEffect } from 'react'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import { Layout, FlowNav } from '@uniqore/module'
import {
  UqTypography,
  UqBox,
  UqList,
  UqListItem,
  UqListItemText,
  UqListItemSecondaryAction
} from '@uniqore/wrapper'
import { layoutProps } from 'Theme'
import AppBarMenu from 'Components/Common/AppBarMenu'
import { useNavigate, useParams } from 'react-router-dom'
import { CATEGORIES, ServiceProviderURLS } from 'App'
import OrderComment from 'Components/Order/OrderComment'
import useTypedSelector from 'Store'
import { round } from 'Components/Cart/Helpers/RoundPrice'
import { useQuery } from '@apollo/client'
import { GET_ORG_USER } from 'GraphQL/queries'
import { getTranslatedItemName } from 'Util/translations'

const ReservationDeclinedPage: FC = () => {
  const navigate = useNavigate()
  const { lang } = useParams()
  const { data } = useQuery(GET_ORG_USER)

  const orgUserEmail = data?.thisUser.email
  const companyName = data?.thisUser.title ?? ''

  const { t } = useTranslation(['Main', 'ServiceProvider', 'Order'])
  const translations = useTypedSelector(state => state.translations)

  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [lang])

  const order = useTypedSelector(state => state.order.currentOrder)
  const activities = order.metadata.activities
    ? order.metadata.activities.filter(
        activity => activity.metadata.serviceProviderEmail === orgUserEmail
      )
    : []

  const providerItems = order.items.filter(
    item => item.metadata.serviceProviderEmail === orgUserEmail
  )
  return (
    <Layout
      {...layoutProps}
      top={<AppBarMenu title={companyName} color="inherit" showBottomBorder={true}></AppBarMenu>}
      bottom={
        <FlowNav
          actionDirection="row"
          actionComponents={[
            {
              text: t('ServiceProvider:logout'),
              variant: 'text',
              startIcon: 'Logout',
              color: 'secondary',
              onClick: () => {
                navigate(`/${lang}/${ServiceProviderURLS.ServiceProviderOtpPage}`)
                localStorage.removeItem('uq_api_token')
              },
              fullWidth: true
            }
          ]}
        />
      }
      showFooter
    >
      <UqBox style={{ display: 'flex', justifyContent: 'center' }}>
        <UqTypography variant="overline">
          {t('Order:order')} #{order.id}
        </UqTypography>
      </UqBox>
      <UqTypography variant="h6" color="text.primary" align="center" marginBottom={8}>
        {t('Order:reservationCancelled')}
      </UqTypography>
      <UqTypography variant="body2" color="text.secondary" align="center">
        {t('Order:reservationCancelledDescription')}
      </UqTypography>
      {providerItems.map((item, index) => {
        return (
          <UqBox
            marginTop={index === 0 ? 24 : 12}
            marginBottom={12}
            style={{ border: '1px solid #80808040' }}
            key={item.sku}
          >
            <UqList disablePadding={true}>
              <UqListItem>
                <UqListItemText
                  primary={`${item.quantity} x ${getTranslatedItemName(
                    item,
                    lang ?? 'fi',
                    translations
                  )}`}
                  secondary={
                    <>
                      {item.metadata.startDateString && (
                        <UqTypography
                          variant="body2"
                          color="text.secondary"
                          component="span" // needed for validate DOM-nesting
                          sx={{ display: 'block' }} // since component is 'span', set display to block
                        >
                          {item.metadata.startDateString} - {item.metadata.endDateString}
                        </UqTypography>
                      )}
                      {item.metadata.category !== CATEGORIES.Activities && item.metadata.pax > 0 && (
                        <UqTypography
                          variant="body2"
                          color="text.secondary"
                          component="span" // needed for validate DOM-nesting
                          sx={{ display: 'block' }} // since component is 'span', set display to block
                        >
                          {item.metadata.pax} {t('Main:guests')}
                        </UqTypography>
                      )}
                      {item.metadata.numericPrice > 0 && (
                        <UqTypography
                          variant="body2"
                          color="text.secondary"
                          component="span" // needed for validate DOM-nesting
                          sx={{ display: 'block' }} // since component is 'span', set display to block
                        >
                          {round(item.metadata.numericPrice)}
                        </UqTypography>
                      )}
                    </>
                  }
                  primaryTypographyProps={{ color: 'text.primary' }}
                  secondaryTypographyProps={{ color: 'text.secondary' }}
                />
                <UqListItemSecondaryAction>
                  <UqTypography color="error.main" variant="button">
                    {t('Main:declinedStatus')}
                  </UqTypography>
                </UqListItemSecondaryAction>
              </UqListItem>
            </UqList>
          </UqBox>
        )
      })}
      {activities.map(item => {
        return (
          <UqBox
            marginTop={12}
            marginBottom={12}
            style={{ border: '1px solid #80808040' }}
            key={item.sku}
          >
            <UqList disablePadding={true}>
              <UqListItem>
                <UqListItemText
                  primary={getTranslatedItemName(item, lang ?? 'fi', translations)}
                  primaryTypographyProps={{ color: 'text.primary' }}
                  secondaryTypographyProps={{ color: 'text.secondary' }}
                />
              </UqListItem>
            </UqList>
          </UqBox>
        )
      })}
      {order.metadata.serviceProviderComment && (
        <OrderComment comment={order.metadata.serviceProviderComment} />
      )}
    </Layout>
  )
}

export default ReservationDeclinedPage
