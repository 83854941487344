import { useEffect } from 'react'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import {
  UqTypography,
  UqDivider,
  UqList,
  UqListItem,
  UqListItemText,
  UqListItemSecondaryAction,
  UqBox
} from '@uniqore/wrapper'
import useTypedSelector from 'Store'
import { useParams } from 'react-router-dom'
import { useCart } from 'Util'
import { round } from 'Components/Cart/Helpers/RoundPrice'
import { CATEGORIES } from 'App'
import { ProductInCart } from 'Features/CartSlice'

const SummaryCart = () => {
  const { lang } = useParams()
  const serviceProvider = useTypedSelector(state => state.airtableData.serviceProvider)
  const cartProducts = useTypedSelector(state => state.cartSlice.products)

  const { cartTotalAmount, cartTotalAmountWithoutTaxes, cartTaxesByPercents } =
    useCart(cartProducts)

  const { t } = useTranslation(['Main', 'ContactDetails'])
  const translations = useTypedSelector(state => state.translations)

  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [lang])

  const getPrice = (item: ProductInCart) => {
    if (item.category === CATEGORIES.CoWorkingSpaces && item.cartMeta.days) {
      return round(item.cartMeta.price)
    }
    if (item.category === CATEGORIES.Accommodation) {
      return round(item.cartMeta.price * item.productQuantity)
    }
    return round(item.cartMeta.price * item.productQuantity)
  }

  const getTranslatedItemName = (item: ProductInCart) => {
    return translations[item.cartMeta.name]
      ? lang === 'en'
        ? translations[item.cartMeta.name].key
        : lang === 'fi'
        ? translations[item.cartMeta.name].fi
        : translations[item.cartMeta.name].sv
      : item.cartMeta.name
  }

  return (
    <UqBox backgroundColor="#fcf7ea" style={{ paddingBottom: 16, border: '1px solid #80808040' }}>
      <UqList disablePadding={true}>
        {cartProducts.map(product => (
          <UqListItem key={product.productId}>
            <UqListItemText
              primary={
                product.productQuantity > 0
                  ? `${getTranslatedItemName(product)} x ${product.productQuantity}`
                  : getTranslatedItemName(product)
              }
              secondary={
                product.category !== CATEGORIES.Activities && (
                  <>
                    <UqTypography
                      variant="body2"
                      color="text.secondary"
                      component="span" // needed for validate DOM-nesting
                      sx={{ display: 'block' }} // since component is 'span', set display to block
                    >
                      {typeof serviceProvider.fields.Name === 'string' &&
                      translations[serviceProvider.fields.Name]
                        ? lang === 'en'
                          ? translations[serviceProvider.fields.Name].key
                          : lang === 'fi'
                          ? translations[serviceProvider.fields.Name].fi
                          : translations[serviceProvider.fields.Name].sv
                        : serviceProvider.fields.Name}
                    </UqTypography>
                    {product.cartMeta.startDate ? (
                      <UqTypography
                        variant="body2"
                        color="text.secondary"
                        component="span" // needed for validate DOM-nesting
                        sx={{ display: 'block' }} // since component is 'span', set display to block
                      >
                        {product.cartMeta.startDate} - {product.cartMeta.endDate}
                      </UqTypography>
                    ) : null}
                    {product.cartMeta.guests ? (
                      <UqTypography
                        variant="body2"
                        color="text.secondary"
                        component="span" // needed for validate DOM-nesting
                        sx={{ display: 'block' }} // since component is 'span', set display to block
                      >
                        {product.cartMeta.guests} {t('Main:guests')}
                      </UqTypography>
                    ) : null}
                  </>
                )
              }
              primaryTypographyProps={{ color: 'text.primary' }}
              secondaryTypographyProps={{ color: 'text.secondary' }}
            />
            <UqListItemSecondaryAction>
              <UqTypography variant="body2">
                {product.category !== CATEGORIES.Activities && getPrice(product)}
              </UqTypography>
            </UqListItemSecondaryAction>
          </UqListItem>
        ))}
      </UqList>
      <UqBox marginHorizontal={16}>
        <UqDivider variant="fullWidth" marginBottom={16} />
        <UqBox marginBottom={8} sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <UqTypography variant="body2" color="text.primary">
            {t('Cart:subtotal')} ({t('Main:alv')}. 0%)
          </UqTypography>
          <UqTypography variant="body2" color="text.primary">
            {cartTotalAmountWithoutTaxes()}
          </UqTypography>
        </UqBox>

        {cartTaxesByPercents(0.1) > 0 && (
          <UqBox sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <UqTypography variant="body2" color="text.primary">
              {t('Main:services')} ({t('Main:alv')}. 10%)
            </UqTypography>
            <UqTypography variant="body2" color="text.primary">
              {round(cartTaxesByPercents(0.1))}
            </UqTypography>
          </UqBox>
        )}

        {cartTaxesByPercents(0.14) > 0 && (
          <UqBox sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <UqTypography variant="body2" color="text.primary">
              {t('Main:services')} ({t('Main:alv')}. 14%)
            </UqTypography>
            <UqTypography variant="body2" color="text.primary">
              {round(cartTaxesByPercents(0.14))}
            </UqTypography>
          </UqBox>
        )}

        {cartTaxesByPercents(0.24) > 0 && (
          <UqBox sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <UqTypography variant="body2" color="text.primary">
              {t('Main:services')} ({t('Main:alv')}. 24%)
            </UqTypography>
            <UqTypography variant="body2" color="text.primary">
              {round(cartTaxesByPercents(0.24))}
            </UqTypography>
          </UqBox>
        )}
        <UqDivider marginVertical={16} />

        <UqBox sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <UqTypography variant="subtitle1" color="text.primary" sx={{ fontWeight: 'bold' }}>
            {t('Cart:total')}
          </UqTypography>
          <UqTypography variant="subtitle1" color="text.primary" sx={{ fontWeight: 'bold' }}>
            {cartTotalAmount()}
          </UqTypography>
        </UqBox>
      </UqBox>
    </UqBox>
  )
}

export default SummaryCart
