import { UqTypography } from '@uniqore/wrapper'
import { truncateLongDesc } from 'Util/productPageCalculations'

interface LongDescriptionProps {
  longDescReadMoreOpen: boolean
  translatedLongDescription: string
  longDescValues: {
    moreText: string
    lessText: string
  }
  setLongDescReadMoreOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const LongDescriptionSection = (props: LongDescriptionProps) => {
  const {
    longDescReadMoreOpen,
    translatedLongDescription,
    longDescValues,
    setLongDescReadMoreOpen
  } = props
  if (translatedLongDescription) {
    return !longDescReadMoreOpen ? (
      <UqTypography
        variant="body2"
        color="text.secondary"
        marginTop={16}
        sx={{
          whiteSpace: 'pre-line'
        }}
      >
        {`${truncateLongDesc(translatedLongDescription, 100).string} `}
        <UqTypography
          variant="body2"
          component="span"
          color="text.secondary"
          onClick={() => setLongDescReadMoreOpen(true)}
          style={
            // Do not display 'read more' text if long description was not truncated
            truncateLongDesc(translatedLongDescription, 100).isTruncated ? {} : { display: 'none' }
          }
          sx={{
            color: '#002444',
            cursor: 'pointer'
          }}
        >
          {longDescValues.moreText}
        </UqTypography>
      </UqTypography>
    ) : (
      <UqTypography
        variant="body2"
        color="text.secondary"
        marginTop={16}
        sx={{
          whiteSpace: 'pre-line'
        }}
      >
        {translatedLongDescription}
        <UqTypography
          variant="body2"
          color="text.secondary"
          component="span"
          onClick={() => setLongDescReadMoreOpen(false)}
          sx={{
            display: 'block',
            color: '#002444',
            cursor: 'pointer'
          }}
        >
          {longDescValues.lessText}
        </UqTypography>
      </UqTypography>
    )
  } else return null
}
