import { CATEGORIES } from 'App'
import { round } from 'Components/Cart/Helpers/RoundPrice'
import { ProductInCart } from 'Features/CartSlice'

export const useCart = (products: ProductInCart[]) => {
  /**
   * Calculates total amount of taxes
   */
  const cartTaxesAmount = (): string => {
    const productsTaxes = products.map(item => {
      if (!isNaN(item.cartMeta.taxAmount)) {
        if (item.category === CATEGORIES.CoWorkingSpaces) {
          return item.cartMeta.taxAmount
        } else if (item.category === CATEGORIES.Accommodation) {
          return item.productQuantity * item.cartMeta.taxAmount
        } else return item.productQuantity * item.cartMeta.taxAmount
      } else return 0
    })
    const taxesAmount = productsTaxes.reduce((a, b) => a + b, 0)
    return round(taxesAmount)
  }

  /**
   * Total amount of the cart (product prices * product quantities)
   */
  const cartTotalAmount = (): string => {
    const productsPrices = products.map(item => {
      if (item.category === CATEGORIES.CoWorkingSpaces) {
        return item.cartMeta.price * item.productQuantity
      } else if (item.category === CATEGORIES.Accommodation) {
        return item.cartMeta.price * item.productQuantity
      } else return item.cartMeta.price * item.productQuantity
    })
    const cartAmount = productsPrices.reduce((a, b) => a + b, 0)
    return round(cartAmount)
  }

  /**
   * Total amount of the cart without taxes
   */
  const cartTotalAmountWithoutTaxes = (): string => {
    const productsPrices = products.map(item => {
      return item.cartMeta.price * item.productQuantity - item.cartMeta.taxAmount
    })
    const subtotal = productsPrices.reduce((a, b) => a + b, 0)
    return round(subtotal)
  }

  const cartTaxesByPercents = (percent: number): number => {
    const productsTaxes = products.map(item => {
      if (!isNaN(item.cartMeta.taxAmount) && item.cartMeta.taxPercent === percent) {
        if (item.category === CATEGORIES.CoWorkingSpaces) {
          return item.cartMeta.taxAmount
        } else if (item.category === CATEGORIES.Accommodation) {
          return item.productQuantity * item.cartMeta.taxAmount
        } else return item.productQuantity * item.cartMeta.taxAmount
      } else return 0
    })
    const taxesAmount = productsTaxes.reduce((a, b) => a + b, 0)
    return taxesAmount
  }

  return {
    cartTaxesAmount,
    cartTotalAmount,
    cartTotalAmountWithoutTaxes,
    cartTaxesByPercents
  }
}
