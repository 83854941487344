import { useEffect, useState } from 'react'
import { Product } from 'Types/Common'
import { useNavigate, useParams } from 'react-router-dom'
import { CATEGORIES, URLS } from 'App'
import useTypedSelector from 'Store'
import { useDispatch } from 'react-redux'
import { setActiveCategory } from 'Features/AirtableSlice'
import ProductsFromCategories from 'Components/Cart/ProductsFromCategories'

const OptionsPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { id, lang, productId } = useParams()
  const activeOptionOrdinalNumber = id
  const activeCategory = useTypedSelector(state => state.airtableData.activeCategory)
  const providerCategories = useTypedSelector(state => state.airtableData.categories)
  const allProducts = useTypedSelector(state => state.airtableData.products)
  const [updatedCategory, setUpdatedCategory] = useState<boolean>(false)

  const [productsFromCategory, setProductsFromCategory] = useState<{
    key: string
    products: Product[]
  }>({
    key: '',
    products: []
  })

  useEffect(() => {
    setUpdatedCategory(false)
    const category = providerCategories.filter(
      option => option.ordinalNumber === Number(activeOptionOrdinalNumber)
    )[0]
    if (category === undefined) {
      dispatch(
        setActiveCategory({
          activeCategory: {
            id: '',
            name: '',
            description: ''
          }
        })
      )
      navigate(`/${lang}/${URLS.CartPage}`)
    } else {
      dispatch(setActiveCategory({ activeCategory: category }))
      setUpdatedCategory(true)
    }
  }, [activeOptionOrdinalNumber])

  useEffect(() => {
    if (activeCategory.name && updatedCategory) {
      const products = allProducts.filter(
        (product: Product) => product.metadata.category === activeCategory.name
      )
      const productsWithRequiredFields = products.filter(product => {
        if (product.metadata.category === CATEGORIES.Accommodation) {
          if (
            product.name &&
            product.metadata.price &&
            product.metadata.highSeasonStart &&
            product.metadata.highSeasonEnd &&
            product.metadata.pax
          ) {
            return product
          }
        } else {
          if (product.name && product.variants[0].price) {
            return product
          }
        }
      })

      const categoryProducts =
        activeCategory.name === CATEGORIES.FoodAndBeverages
          ? productsWithRequiredFields
              .sort((a, b) =>
                a.variants[0].metadata.unit.localeCompare(b.variants[0].metadata.unit)
              )
              .sort((a, b) => a.metadata.sorting - b.metadata.sorting)
          : productsWithRequiredFields

      setProductsFromCategory({
        key: activeCategory.name,
        products: categoryProducts
      })

      if (productsWithRequiredFields?.length === 0) {
        navigate('/en/cart')
      }
    }
  }, [activeCategory, activeOptionOrdinalNumber, updatedCategory])

  return (
    <ProductsFromCategories
      productsFromCategory={productsFromCategory.products}
      key={productsFromCategory.key}
      productId={productId}
    />
  )
}

export default OptionsPage
