import { CATEGORIES } from 'App'
import { round } from 'Components/Cart/Helpers/RoundPrice'
import { CurrentOrderItem, CartProductToOrder } from 'Features/OrderSlice'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import useTypedSelector from 'Store'
import HeadingListItem from './HeadingListItem'
import ServiceStatusCard from './ServiceStatusCard'
import { getTranslatedItemName, getTranslatedStatus } from 'Util/translations'

interface OrderProductsProps {
  products: CurrentOrderItem[]
  activitiesInCart: CartProductToOrder[]
}

const OrderProducts = (props: OrderProductsProps) => {
  const { products, activitiesInCart } = props
  const { lang } = useParams()

  const { t } = useTranslation(['Main'])
  const translations = useTypedSelector(state => state.translations)

  const spacesInCart = products.filter(
    product => product.metadata.category === CATEGORIES.CoWorkingSpaces
  )
  const accommodationInCart = products.filter(
    product => product.metadata.category === CATEGORIES.Accommodation
  )
  const foodAndBeveragesInCart = products.filter(
    product => product.metadata.category === CATEGORIES.FoodAndBeverages
  )

  return (
    <>
      {spacesInCart && spacesInCart.length > 0 && (
        <>
          <HeadingListItem
            title={t('Main:coWorkingSpace')}
            subtitle={
              translations[spacesInCart[0].metadata.serviceProvider]
                ? lang === 'en'
                  ? translations[spacesInCart[0].metadata.serviceProvider].key
                  : lang === 'fi'
                  ? translations[spacesInCart[0].metadata.serviceProvider].fi
                  : translations[spacesInCart[0].metadata.serviceProvider].sv
                : spacesInCart[0].metadata.serviceProvider
            }
          />
          {spacesInCart.map(space => {
            return (
              <ServiceStatusCard
                key={space.sku}
                title={getTranslatedItemName(space, lang ?? 'fi', translations)}
                subtitles={[
                  `${space.metadata.startDateString} - ${space.metadata.endDateString}`,
                  `${space.metadata.pax} ${t('Main:guests')}`,
                  `${round(space.metadata.numericPrice * space.quantity)}`
                ]}
                status={getTranslatedStatus(space, lang ?? 'fi', translations)}
              />
            )
          })}
        </>
      )}
      {accommodationInCart && accommodationInCart.length > 0 && (
        <>
          <HeadingListItem
            title={t('Main:accommodation')}
            subtitle={
              translations[accommodationInCart[0].metadata.serviceProvider]
                ? lang === 'en'
                  ? translations[accommodationInCart[0].metadata.serviceProvider].key
                  : lang === 'fi'
                  ? translations[accommodationInCart[0].metadata.serviceProvider].fi
                  : translations[accommodationInCart[0].metadata.serviceProvider].sv
                : accommodationInCart[0].metadata.serviceProvider
            }
          />
          {accommodationInCart.map(accommodation => {
            return (
              <ServiceStatusCard
                key={accommodation.sku}
                title={`${accommodation.quantity} x ${getTranslatedItemName(
                  accommodation,
                  lang ?? 'fi',
                  translations
                )}`}
                subtitles={[
                  `${accommodation.metadata.startDateString} - ${accommodation.metadata.endDateString}`,
                  `${accommodation.metadata.pax} ${t('Main:guests')}`,
                  `${round(accommodation.metadata.numericPrice * accommodation.quantity)}`
                ]}
                status={getTranslatedStatus(accommodation, lang ?? 'fi', translations)}
              />
            )
          })}
        </>
      )}
      {foodAndBeveragesInCart && foodAndBeveragesInCart.length > 0 && (
        <>
          <HeadingListItem
            title={t('Main:foodAndBeverages')}
            subtitle={
              translations[foodAndBeveragesInCart[0].metadata.serviceProvider]
                ? lang === 'en'
                  ? translations[foodAndBeveragesInCart[0].metadata.serviceProvider].key
                  : lang === 'fi'
                  ? translations[foodAndBeveragesInCart[0].metadata.serviceProvider].fi
                  : translations[foodAndBeveragesInCart[0].metadata.serviceProvider].sv
                : foodAndBeveragesInCart[0].metadata.serviceProvider
            }
          />
          {foodAndBeveragesInCart.map(item => {
            return (
              <ServiceStatusCard
                key={item.sku}
                title={`${item.quantity} x ${getTranslatedItemName(
                  item,
                  lang ?? 'fi',
                  translations
                )} / ${item.metadata.unit.toLowerCase()}`}
                subtitles={[`${round(item.metadata.numericPrice * item.quantity)}`]}
                status={getTranslatedStatus(item, lang ?? 'fi', translations)}
              />
            )
          })}
        </>
      )}
      {activitiesInCart && activitiesInCart.length > 0 && (
        <>
          <HeadingListItem
            title={t('Main:activites')}
            subtitle={
              translations[activitiesInCart[0].metadata.serviceProvider]
                ? lang === 'en'
                  ? translations[activitiesInCart[0].metadata.serviceProvider].key
                  : lang === 'fi'
                  ? translations[activitiesInCart[0].metadata.serviceProvider].fi
                  : translations[activitiesInCart[0].metadata.serviceProvider].sv
                : activitiesInCart[0].metadata.serviceProvider
            }
          />
          {activitiesInCart.map(activity => {
            return (
              <ServiceStatusCard
                key={activity.sku}
                title={getTranslatedItemName(activity, lang ?? 'fi', translations)}
                subtitles={[]}
                status=""
              />
            )
          })}
        </>
      )}
    </>
  )
}
export default OrderProducts
