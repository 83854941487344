import React from 'react'
import { BrowserRouter, Route, Routes, Navigate, useParams } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import Snackbar from 'Components/Common/Snackbar'
import {
  SpacePage,
  CartPage,
  OptionsPage,
  CompanyDetailsPage,
  ContactDetailsPage,
  SummaryPage,
  FinalSplash,
  OrderStatusPage,
  ErrorPage
} from 'Pages/EndUserPages'
import {
  ServiceProviderOtpPage,
  ConfirmRequestPage,
  ReservationConfirmedPage,
  ReservationDeclinedPage
} from 'Pages/ServiceProviderPages'
import { UqBackdrop, UqCircularProgress } from '@uniqore/wrapper'
import { useTranslations } from 'Util/useTranslations'

export const URLS = {
  SpacePage: 'space',
  CartPage: 'cart',
  CompanyDetailsPage: 'company-details',
  ContactDetailsPage: 'contact-details',
  SummaryPage: 'summary',
  FinalSplashPage: 'preorder-created',
  OrderStatusPage: 'order/:id',
  ErrorPage: 'error'
}

export const ServiceProviderURLS = {
  ServiceProviderOtpPage: 'login',
  ConfirmRequestPage: 'confirm-request',
  ReservationConfirmedPage: 'reservation-confirmed',
  ReservationDeclinedPage: 'reservation-declined'
}

export const CATEGORIES = {
  CoWorkingSpaces: 'Co-working Spaces',
  Accommodation: 'Accommodation',
  FoodAndBeverages: 'Food & Beverages',
  Activities: 'Activities'
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ProtectedRoute = ({ children }: { children: any }) => {
  const token = localStorage.getItem('uq_api_token')
  const { lang, id } = useParams()

  if (id) {
    localStorage.setItem('apelagoOrderId', id)
  }
  if (!token) {
    return <Navigate to={`/${lang}/login`} replace />
  }
  return children
}

const App: React.FC = () => {
  const { loading } = useTranslations()
  return (
    <>
      <UqBackdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
        <UqCircularProgress color="inherit" />
      </UqBackdrop>
      <BrowserRouter>
        <ErrorBoundary
          FallbackComponent={ErrorPage}
          onReset={() => {
            window.location.reload()
            // reset the state of your app so the error doesn't happen again
          }}
        >
          <Routes>
            <Route element={<ErrorPage />} path={`/:lang/${URLS.ErrorPage}`} />
            <Route element={<SpacePage />} path={`/:lang/${URLS.SpacePage}/:providerId`} />
            <Route element={<SpacePage />} path={`/:lang/${URLS.SpacePage}/:providerId/:spaceId`} />
            <Route element={<CartPage />} path={`/:lang/${URLS.CartPage}`} />
            <Route element={<OptionsPage />} path={`/:lang/cart/:id`} />
            <Route element={<OptionsPage />} path={`/:lang/cart/:id/:productId`} />
            <Route element={<CompanyDetailsPage />} path={`/:lang/${URLS.CompanyDetailsPage}`} />
            <Route element={<ContactDetailsPage />} path={`/:lang/${URLS.ContactDetailsPage}`} />
            <Route element={<SummaryPage />} path={`/:lang/${URLS.SummaryPage}`} />
            <Route element={<FinalSplash />} path={`/:lang/${URLS.FinalSplashPage}`} />
            <Route element={<OrderStatusPage />} path={`/:lang/${URLS.OrderStatusPage}`} />
            <Route
              element={<ServiceProviderOtpPage />}
              path={`/:lang/${ServiceProviderURLS.ServiceProviderOtpPage}`}
            />
            <Route
              path={`/:lang/${ServiceProviderURLS.ConfirmRequestPage}/:id`}
              element={
                <ProtectedRoute>
                  <ConfirmRequestPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/:lang/${ServiceProviderURLS.ReservationConfirmedPage}`}
              element={
                <ProtectedRoute>
                  <ReservationConfirmedPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/:lang/${ServiceProviderURLS.ReservationDeclinedPage}`}
              element={
                <ProtectedRoute>
                  <ReservationDeclinedPage />
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<Navigate to={`/en/${URLS.ErrorPage}`} replace />} />
          </Routes>
        </ErrorBoundary>
      </BrowserRouter>
      <Snackbar />
    </>
  )
}
export { App }
export default App
