import {
  UqBox,
  UqList,
  UqListItem,
  UqListItemText,
  UqTypography,
  UqListItemSecondaryAction
} from '@uniqore/wrapper'

interface HeadingListItemProps {
  title: string
  subtitles: string[]
  status: string
}

const ServiceStatusCard = (props: HeadingListItemProps) => {
  const { title, subtitles, status } = props
  const confirmedStatus =
    status === 'Confirmed' || status === 'Vahvistettu' || status === 'Bekräftad'
  const declinedStatus = status === 'Declined' || status === 'Hylätty' || status === 'Avvisad'
  return (
    <UqBox
      style={{ border: '1px solid #80808040' }}
      marginBottom={16}
      marginLeft={16}
      marginRight={16}
    >
      <UqList disablePadding={true}>
        <UqListItem>
          <UqListItemText
            primary={title}
            secondary={subtitles.map(subtitle => (
              <UqTypography
                key={subtitle}
                variant="body2"
                color="text.secondary"
                component="span" // needed for validate DOM-nesting
                sx={{ display: 'block' }} // since component is 'span', set display to block
              >
                {subtitle}
              </UqTypography>
            ))}
            primaryTypographyProps={{ color: 'text.primary' }}
            secondaryTypographyProps={{ color: 'text.secondary' }}
          />
          <UqListItemSecondaryAction>
            <UqTypography
              color={confirmedStatus ? 'success.main' : declinedStatus ? 'error.main' : 'secondary'}
              variant="button"
            >
              {status}
            </UqTypography>
          </UqListItemSecondaryAction>
        </UqListItem>
      </UqList>
    </UqBox>
  )
}

export default ServiceStatusCard
