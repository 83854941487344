import { useDispatch } from 'react-redux'
import { FETCH_DATAVIEW } from 'GraphQL/queries'
import { useQuery } from '@apollo/client'
import { editSnackbar } from 'Features/snackbar'
import { setServiceProvider } from 'Features/AirtableSlice'
import { useEffect } from 'react'
import { ServiceProvider } from 'Types/Space'

interface AirtableServiceProvderDataViewData {
  dataview: {
    data: {
      records: ServiceProvider[]
    }
  }
}

export const useSpace = (providerId: string | null) => {
  const dispatch = useDispatch()

  const { data: providerData, loading: providerLoading } =
    useQuery<AirtableServiceProvderDataViewData>(FETCH_DATAVIEW, {
      variables: {
        id: process.env.REACT_APP_DATAVIEW_GET_SERVICE_PROVIDERS_ID
      },
      onError(err) {
        dispatch(editSnackbar({ message: err.message, type: 'error' }))
      }
    })

  useEffect(() => {
    if (providerData) {
      const airtableServiceProvider = providerData.dataview.data.records.find(
        provider => provider.id === providerId
      )
      if (airtableServiceProvider) {
        dispatch(setServiceProvider({ airtableServiceProvider: airtableServiceProvider }))
      }
    }
  }, [providerData])

  return { providerLoading }
}
