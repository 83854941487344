import { useEffect } from 'react'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import {
  UqDialog,
  UqDialogTitle,
  UqDialogContent,
  UqDialogContentText,
  UqDialogActions,
  UqButton
} from '@uniqore/wrapper'
import { Dispatch, SetStateAction } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

interface DialogProps {
  showDialog: boolean
  setShowDialog: Dispatch<SetStateAction<boolean>>
  orderId: string
  status: string
}

const FailedPaymentDialog = (props: DialogProps) => {
  const navigate = useNavigate()

  const { lang } = useParams()

  const { t } = useTranslation(['Order'])

  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [lang])

  const { showDialog, setShowDialog, orderId, status } = props
  const closeDialog = () => {
    setShowDialog(false)
    navigate(`/${lang}/order/${orderId}?status=${status}`)
  }
  return (
    <UqDialog open={showDialog} fullWidth>
      <UqDialogTitle>{t('Order:failedPaymentDialogTitle')}</UqDialogTitle>
      <UqDialogContent>
        <UqDialogContentText>{t('Order:failedPaymentDialogText')}</UqDialogContentText>
      </UqDialogContent>
      <UqDialogActions style={{ marginBottom: 8 }}>
        <UqButton onClick={() => closeDialog()}>
          {t('Order:failedPaymentDialogButtonText')}
        </UqButton>
      </UqDialogActions>
    </UqDialog>
  )
}

export default FailedPaymentDialog
