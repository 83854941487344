import { CurrentOrderItem, CartProductToOrder } from 'Features/OrderSlice'

export const getTranslatedItemName = (
  item: CurrentOrderItem | CartProductToOrder,
  lang: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  translations: any
) => {
  return translations[item.metadata.productName]
    ? lang === 'en'
      ? translations[item.metadata.productName].key
      : lang === 'fi'
      ? translations[item.metadata.productName].fi
      : translations[item.metadata.productName].sv
    : item.metadata.productName
}

export const getTranslatedStatus = (
  item: CurrentOrderItem,
  lang: string, // eslint-disable-next-line @typescript-eslint/no-explicit-any
  translations: any
) => {
  return translations[item.metadata.status]
    ? lang === 'en'
      ? translations[item.metadata.status].key
      : lang === 'fi'
      ? translations[item.metadata.status].fi
      : translations[item.metadata.status].sv
    : item.metadata.status
}
