import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface AirtableTranslate {
  fields: {
    Key: string
    Fi: string
    Sv: string
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const initialState: any = {}

const translationsSlice = createSlice({
  name: 'translations',
  initialState,
  reducers: {
    setTranslations: (
      state,
      action: PayloadAction<{ airtableTranslations: AirtableTranslate[] }>
    ) => {
      const { airtableTranslations } = action.payload

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const translations: any = airtableTranslations.reduce(
        (obj, item) =>
          Object.assign(obj, {
            [item.fields.Key]: {
              key: item.fields.Key,
              fi: item.fields.Fi,
              sv: item.fields.Sv
            }
          }),
        {}
      )

      state = translations
      return state
    }
  }
})

export const { setTranslations } = translationsSlice.actions
export default translationsSlice.reducer
